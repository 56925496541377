import DATA from '../datas'
import ENVIRONMENT from '../constants/environment'
import { IPackage } from '@evos/general-particle/dist/interfaces/account'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'

const GetList = async (
  env: ENVIRONMENT
): Promise<Array<IPackage> | undefined> => {
  let result
  await Base(
    env,
    DATA.Package.GetList(env),
    (response: IResponse<Array<IPackage>>) => {
      result = response.data?.data
    }
  )
  return result
}

export default {
  GetList
}
