/* eslint-disable react/no-did-update-set-state */
import * as React from 'react'
import Input, { INPUT_TYPE } from '../input'
import Checkbox from '../input/input.checkbox'
import Select from '../select'
import { IBaseProps } from '../../interfaces/base'
import { ILogin } from '../../interfaces/auth'
import Button, { IButtonProps } from '../button'
import Text from '../text'
import T from '../../utils/translation'
import Column from '../grid/column'
import Row from '../grid/row'
import moduleStyles from '../../modules.style.css'
import GeneralParticle from '@evos/general-particle'

const { Email } = GeneralParticle.Utils.Validation
export interface IField {
  /*
   * field label
   */
  label: string
  /*
   * field placeholder
   */
  placeholder?: string
  /*
   * field type
   * default is text
   */
  type: INPUT_TYPE | 'select' | 'checkbox'
  /*
   * field name
   */
  name: string
  /*
   * field value
   */
  value?: any
  /*
   * wether field is required or not
   */
  required?: boolean
  /*
   * define max length of the field
   * applicable for INPUT_TYPE only
   */
  maxLength?: number
  /*
   * define min length of the field
   * applicable for INPUT_TYPE only
   */
  minLength?: number
  /*
   * field options
   * applicable for select only
   */
  options?: Array<[]>
  /*
   * function triggered when there is value changes
   */
  onChange?: (value: any) => void
  /*
   * wether options is searchable or not
   * applicable on select only
   */
  isSearchable?: boolean
  /*
   * define row of filed
   * applicable on textarea type only
   */
  rows?: number
  /*
   * field's error message
   */
  error?: string
  /*
   * size of field
   */
  size?: number
  /**
   * editable field
   */
  readonly?: boolean
  /**
   * matching ref
   * matching and matching variable
   */
  matchingRef?: boolean
  match?: boolean
  matchingVar?: string
  /**
   * matching error messsage
   */
  matchError?: string
  /**
   * add title over label
   */
  title?: string
}

interface IProps
  extends IBaseProps,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  /*
   * function triggered when form passed validation
   */
  onComplete?: (value?: Array<IField>) => void
  /*
   * array field of form
   */
  fields: Array<IField>
  /*
   * form`s button
   */
  button?: IButtonProps
  /**
   * additional element
   */
  additionalElement?: JSX.Element
  /**
   * additional error message
   */
  additionalErrMsg?: string
}

interface IStates extends ILogin {
  errMessage?: string
  fields: Array<IField>
  overlayExists: boolean
}

class DefaultForm extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      errMessage: this.props.additionalErrMsg,
      fields: this.props.fields,
      overlayExists: false
    }
  }

  componentDidUpdate(previousProps: IProps) {
    const { additionalErrMsg } = this.props
    if (previousProps.additionalErrMsg !== additionalErrMsg) {
      this.setState({ errMessage: additionalErrMsg })
    }

    if (this.props.fields !== previousProps.fields) {
      this.setState({ fields: this.props.fields })
    }
  }

  componentDidMount = () => {
    this.setState({
      fields: this.props.fields
    })
  }

  onChange = (e: any) => {
    const { fields } = this.state
    const i = fields.findIndex((f: IField) => f.name === e.target.name)
    fields[i].value = e.target.value
    if (fields[i].matchingRef) fields[i + 1].matchingVar = e.target.value
    this.setState({ fields, errMessage: '' })
  }

  onCheck = (e: any) => {
    const { fields } = this.state
    const i = fields.findIndex((f: IField) => f.name === e.target.name)
    fields[i].value = e.target.checked
    this.setState({ fields })
  }

  onSelectChange = ({ name, value }: any) => {
    const { fields } = this.state
    const i = fields.findIndex((f: IField) => f.name === name)
    fields[i].value = value
    this.setState({ fields })
  }

  validation = () => {
    const { language, onComplete }: IProps = this.props
    const { fields } = this.state
    fields.map((f: IField) => {
      if (f.required && !f.value) {
        f.error = T('fill field', { locale: language })
      } else {
        f.error = ''
      }
      if (f.required && f.type === 'email' && !Email(f.value)) {
        f.error = T('invalid email', { locale: language })
      }
      if (f.type === 'select' && f.required && !f.value) {
        f.error = T('please select one', { locale: language })
      }
      if (f.minLength && f.value && f.value.trim().length < f.minLength) {
        f.error = T('min char', { locale: language, number: f.minLength })
      }
      if (f.maxLength && f.value && f.value.trim().length > f.maxLength) {
        f.error = T('max char', { locale: language, number: f.maxLength })
      }
      if (f.match && f.matchingVar !== f.value) {
        f.error = f.matchError
      }
    })
    this.setState({ fields }, () => {
      const arrError = fields.filter((f) => f.error !== '' || undefined)
      if (arrError.length === 0) {
        onComplete && onComplete(fields)
      }
    })
  }

  onSubmit = () => {
    this.validation()
  }

  render() {
    const { language, button, env, style, additionalElement } = this.props
    const { errMessage, fields, overlayExists } = this.state
    const renderComponent = (f: IField, key: number) => {
      switch (f.type) {
        case 'select':
          return (
            <Column fullWidth className={`${moduleStyles.mb3}`}>
              <Select
                env={env}
                language={language}
                label={f.label}
                error={f.error}
                value={f.value}
                name={f.name}
                options={this.props.fields[key].options}
                onChange={({ name, value }: any) => {
                  this.onSelectChange({ name, value })
                  if (f.onChange) f.onChange({ name, value })
                }}
                onClick={(overlayShown: boolean) => {
                  this.setState({ overlayExists: overlayShown })
                }}
                placeholder={f.placeholder}
                isSearchable={f.isSearchable}
              />
            </Column>
          )
          break
        case 'checkbox':
          return (
            <Row fullWidth align='center' className={`${moduleStyles.mb3}`}>
              <Checkbox
                env={env}
                language={language}
                checked={f.value}
                name={f.name}
                onChange={(e) => {
                  this.onCheck(e)
                  if (f.onChange)
                    f.onChange({ name: f.name, value: e.target.checked })
                }}
              />
              <Text size={14} lineHeight={18}>
                {f.label}
              </Text>
            </Row>
          )
          break
        default:
          return (
            <Column fullWidth>
              <Text
                size={14}
                color='primary-black'
                lineHeight={21}
                className={`${moduleStyles.mb1}`}
              >
                {f.label}
              </Text>
              <Input
                env={env}
                language={language}
                type={f.type}
                error={f.error}
                name={f.name}
                value={f.value}
                placeholder={f.placeholder}
                onChange={(e) => {
                  this.onChange(e)
                  if (f.onChange)
                    f.onChange({ name: f.name, value: e.target.value })
                }}
                required={f.required}
                maxLength={f.maxLength}
                minLength={f.minLength}
                rows={f.rows}
                readOnly={f.readonly}
              />
            </Column>
          )
          break
      }
    }

    return (
      <Column
        fullWidth
        style={{ ...style, overflow: overlayExists ? 'hidden' : 'auto' }}
      >
        {fields.map((i: IField, key: number) => (
          <Row fullWidth fullFlex key={key}>
            <Column fullWidth fullFlex>
              {i.title ? (
                <Row fullWidth fullFlex className={moduleStyles.mv1}>
                  <Text weight='bold' size={14}>
                    {i.title}
                  </Text>
                </Row>
              ) : undefined}
              <Row fullWidth fullFlex className={moduleStyles.mb3}>
                {renderComponent(i, key)}
              </Row>
            </Column>
          </Row>
        ))}
        {errMessage && errMessage !== '' && (
          <Row className={moduleStyles.mb3}>
            <Text color='red' size={12}>
              {errMessage && errMessage}
            </Text>
          </Row>
        )}
        {additionalElement}
        <Row fullWidth justify='end'>
          <Button
            variant={button?.variant}
            disabled={button?.disabled}
            onClick={this.onSubmit}
            className={`${moduleStyles.ph3}`}
            block={button?.block}
            label={button?.label}
            isLoading={button?.isLoading}
          />
        </Row>
      </Column>
    )
  }
}

export default DefaultForm
