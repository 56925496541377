import '../../modules.style.css'

import * as React from 'react'
import styles from './index.style.css'

interface IProps {
  children: React.ReactNode
  visible: boolean
  width: number
  onClose?: () => void
}

const ActionSheetSharedComponent = ({
  children,
  visible,
  width,
  onClose
}: IProps) => {
  if (!visible) return null
  return (
    <div className={styles['action-sheet']} style={{ width }}>
      <div className={styles.overlay} onClick={onClose} />
      <div className={styles.body}>{children}</div>
    </div>
  )
}

ActionSheetSharedComponent.defaultProps = {
  onClose: undefined
}

export default ActionSheetSharedComponent
