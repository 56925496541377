import ENVIRONMENT from '../constants/environment'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'
import { IPackage } from '@evos/general-particle/dist/interfaces/account'

export default {
  GetList: (env: ENVIRONMENT): Promise<IResponse<Array<IPackage>>> => {
    return Base(env).get(`/accounts/v1/public/packages`)
  }
}
