import { IResponse } from '@evos/general-particle/dist/interfaces/api'
import DATA from '../datas'
import ENVIRONMENT from '../constants/environment'
import Base from './base'
import { IMetadata } from '@evos/general-particle/dist/interfaces/shop'

const GetList = async (
  env: ENVIRONMENT,
  types: string,
  onSuccess?: (data: Array<IMetadata>) => any,
  onFailed?: (error?: string) => any,
  onCallback?: (res: any) => any
): Promise<Array<IMetadata> | undefined> => {
  let result
  await Base(
    env,
    DATA.Metadata.GetList(env, types),
    (response: IResponse<Array<IMetadata>>) => {
      if (response.data?.success && response.data?.data) {
        onSuccess && onSuccess(response.data.data)
      } else {
        onFailed &&
          onFailed(response.data?.errors?.map((x) => x.message).join(','))
      }
    },
    (err) => {
      onFailed && onFailed(err)
    },
    (final) => {
      onCallback && onCallback(final)
    }
  )
  return result
}

export default {
  GetList
}
