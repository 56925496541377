import DATA from '../datas'
import ENVIRONMENT from '../constants/environment'
import {
  IUser,
  IProvider
} from '@evos/general-particle/dist/interfaces/account'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'

const GetRedirectUrl = async (
  env: ENVIRONMENT,
  provider: string
): Promise<{ redirectUrl: string } | undefined> => {
  let result
  await Base(
    env,
    DATA.Provider.GetRedirectUrl(env, provider),
    (response: IResponse<{ redirectUrl: string }>) => {
      result = response.data?.data
    }
  )
  return result
}

const GetCallback = async (
  env: ENVIRONMENT,
  provider: string,
  value: string
): Promise<IUser | undefined> => {
  let result
  await Base(
    env,
    DATA.Provider.GetCallback(env, provider, value),
    (response: IResponse<IUser>) => {
      result = response.data?.data
    }
  )
  return result
}

const GetList = async (
  env: ENVIRONMENT
): Promise<Array<IProvider> | undefined> => {
  let result
  await Base(
    env,
    DATA.Provider.GetList(env),
    (response: IResponse<Array<IProvider>>) => {
      result = response.data?.data
    }
  )
  return result
}

export default {
  GetRedirectUrl,
  GetCallback,
  GetList
}
