import * as React from 'react'
import API from '../../apis'
import { IBaseProps } from '../../interfaces/base'
import { ILogin } from '../../interfaces/auth'
import T from '../../utils/translation'
import Row from '../../shared-components/grid/row'
import Column from '../../shared-components/grid/column'
import DefaultForm, { IField } from '../../shared-components/default-form'
import Button, { IButtonProps } from '../../shared-components/button'
import { IUser } from '@evos/general-particle/dist/interfaces/account'
import styles from './index.style.css'
import moduleStyles from '../../modules.style.css'
import STORAGE from '../../constants/storage'
import GeneralParticle from '@evos/general-particle'
import ENVS from '../../utils/environment'

const { GetQuery } = GeneralParticle.Utils.URL
const { Set } = GeneralParticle.Utils.Storage

interface IProps extends IBaseProps {
  onSuccess?: (value?: IUser) => void
  onFailure?: (value?: string) => void
  onForgotPassword?: () => void
}

interface IStates extends ILogin {
  isLoading?: boolean
  fields: Array<IField>
}

class LoginForm extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    const { language } = props
    this.state = {
      isLoading: false,
      fields: [
        {
          label: T('email', { locale: language }),
          placeholder: T('input valid email', { locale: language }),
          type: 'email',
          name: 'email',
          required: true
        },
        {
          label: T('password', { locale: language }),
          placeholder: T('input password', { locale: language }),
          type: 'password',
          name: 'password',
          required: true,
          minLength: 8
        }
      ] as Array<IField>
    }
  }

  onComplete = (fields: Array<IField>) => {
    const finalFields: { [key: string]: any } = {}
    fields.forEach((x) => {
      finalFields[x.name] = x.value
    })
    this.setState(
      {
        ...finalFields,
        fields
      },
      () => this.login(finalFields)
    )
  }

  login = (data: ILogin) => {
    const { env, onSuccess, onFailure } = this.props
    this.setState({ isLoading: true })
    API.Auth.Login(
      env,
      'email',
      data,
      (user: IUser) => {
        const ref = GetQuery('referrer') || document.referrer
        Set(
          STORAGE.referrer + ENVS[env].STORAGE_SUFFIX,
          ref,
          ENVS[env].STORAGE_DOMAIN
        )
        onSuccess && onSuccess(user)
      },
      (err) => {
        onFailure && onFailure(err)
      },
      () => {
        this.setState({ isLoading: false })
      }
    )
  }

  render() {
    const { isLoading } = this.state
    const { language, env, onForgotPassword } = this.props

    const BUTTON: IButtonProps = {
      label: T('login', { locale: language }),
      isLoading: isLoading,
      disabled: isLoading,
      variant: 'primary',
      block: true
    }

    const FORGOT_BUTTON = (
      <Row fullWidth className={`${moduleStyles.mb3}`} justify='end'>
        <Button
          variant='transparent'
          onClick={onForgotPassword}
          className={`${styles.button} ${moduleStyles.ph3}`}
          label={T('forgot password', { locale: language })}
          textSize={14}
          weight='regular'
        />
      </Row>
    )

    return (
      <Column fullWidth>
        <DefaultForm
          env={env}
          fields={this.state.fields}
          button={BUTTON}
          additionalElement={FORGOT_BUTTON}
          language={language}
          onComplete={this.onComplete}
        />
      </Column>
    )
  }
}

export default LoginForm
