import * as React from 'react'
import Styles from './index.style.css'

interface IProps {
  size?: number
  style?: React.CSSProperties
}

const Spinner = (props: IProps): React.ReactElement => (
  <div
    className={Styles.loader}
    style={{
      width: props.size ? props.size : 10,
      height: props.size ? props.size : 10,
      ...props.style
    }}
  />
)

export default Spinner
