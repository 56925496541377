import '../../modules.style.css'

import * as React from 'react'
import styles from './index.style.css'

interface IProps {
  children: React.ReactNode
  weight?: 'bold' | 'semibold' | 'regular'
  size?: number
  lineHeight?: number
  className?: string
  color?:
    | 'primary-black'
    | 'primary-blue'
    | 'secondary-black'
    | 'tertiary-black'
    | 'white'
    | 'red'
    | 'gray'
    | 'inherit'
  background?:
    | 'primary-black'
    | 'primary-blue'
    | 'secondary-black'
    | 'tertiary-black'
    | 'white'
    | 'transparent'
  align?: 'left' | 'center' | 'right'
}

const TextSharedComponent = ({
  children,
  weight,
  size,
  lineHeight,
  className,
  color,
  background,
  align
}: IProps) => {
  let w = 400
  if (weight === 'semibold') w = 600
  if (weight === 'bold') w = 700
  return (
    <span
      style={{
        fontSize: size,
        lineHeight: `${lineHeight}px`,
        fontWeight: w,
        textAlign: align,
        overflowWrap: 'anywhere'
      }}
      className={`${className} ${styles[`color-${color}`]} ${
        styles[`bg-${background}`]
      }`}
    >
      {children}
    </span>
  )
}

TextSharedComponent.defaultProps = {
  weight: 'regular',
  size: 16,
  lineHeight: 16,
  className: '',
  color: 'primary-black',
  background: 'transparent'
}

export default TextSharedComponent
