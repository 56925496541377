import ENVIRONMENT from '../constants/environment'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'
import { ICountry } from '@evos/general-particle/dist/interfaces/account'

export default {
  GetList: (env: ENVIRONMENT): Promise<IResponse<Array<ICountry>>> => {
    return Base(env).get(`/v1/countries/language`)
  }
}
