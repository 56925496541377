import ENVIRONMENT from '../constants/environment'
import {
  ILogin,
  IRegister,
  IForgotPassword,
  IResetPassword,
  IChangePassword,
  IVerifyEmail
} from '../interfaces/auth'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'
import { IUser } from '@evos/general-particle/dist/interfaces/account'

export default {
  Register: (env: ENVIRONMENT, data: IRegister): Promise<IResponse<IUser>> => {
    return Base(env).post(`/accounts/v1/public/auth/register`, { ...data })
  },
  Login: (
    env: ENVIRONMENT,
    provider: string,
    data: ILogin
  ): Promise<IResponse<IUser>> => {
    return Base(env).post(`/accounts/v1/public/auth/${provider}`, { ...data })
  },
  ForgotPassword: (
    env: ENVIRONMENT,
    data: IForgotPassword
  ): Promise<IResponse<{ email: string }>> => {
    return Base(env).post('/accounts/v1/public/auth/forgot-password', {
      ...data
    })
  },
  ResetPassword: (
    env: ENVIRONMENT,
    data: IResetPassword
  ): Promise<IResponse<null>> => {
    return Base(env).post('/accounts/v1/public/auth/reset-password', {
      ...data
    })
  },
  ChangePassword: (
    env: ENVIRONMENT,
    data: IChangePassword
  ): Promise<IResponse<null>> => {
    return Base(env).put('/accounts/v1/private/users/change-password', {
      ...data
    })
  },
  ValidateSignature: (
    env: ENVIRONMENT,
    signature: string,
    userId: string,
    timestamp: string
  ): Promise<IResponse<null>> => {
    return Base(env).get(
      `/accounts/v1/public/auth/validate-signature-reset-password/${timestamp}/${userId}/${signature}`
    )
  },
  VerifyUser: (env: ENVIRONMENT, data: any): Promise<any> =>
    Base(env).post('/accounts/v1/public/auth/verified-user-phonenumber', data),
  SendEmail: (
    env: ENVIRONMENT,
    data: IVerifyEmail
  ): Promise<IResponse<{ email: string }>> => {
    return Base(env).post(
      '/accounts/v1/private/users/send-email-verification',
      {
        ...data
      }
    )
  },
  VerifiedEmailUser: (
    env: ENVIRONMENT,
    data: IVerifyEmail
  ): Promise<IResponse<{ email: string }>> => {
    return Base(env).put('/accounts/v1/private/users/verified-email', {
      ...data
    })
  },
  ValidateSignatureEmail: (
    env: ENVIRONMENT,
    signature: string,
    userId: string,
    timestamp: string,
    email: string
  ): Promise<IResponse<null>> => {
    return Base(env).get(
      `accounts/v1/public/auth/validate-signature-verification-email/${email}/${timestamp}/${userId}/${signature}`
    )
  }
}
