const ASSET = {
  rightSign: 'https://uploads-member.evosesports.com/VIHes4tRBaxVhiNVLlbX.png',
  arrowBack: 'https://uploads-member.evosesports.com/FOlUUTFw5Zuz9qaRksRl.png',
  pencil: 'https://uploads-member.evosesports.com/fNBjeFtCtRUva1055CPs.png',
  case: 'https://uploads.dev.evos.gg/web/case.png',

  lightning: 'https://uploads.dev.evos.gg/web/lightning.png',
  show: 'https://uploads.dev.evos.gg/web/show.png',
  hide: 'https://uploads.dev.evos.gg/web/hide.png',
  close: 'https://uploads.dev.evos.gg/web/close.png',
  option: 'https://uploads.dev.evos.gg/web/option.png',
  checklist: 'https://uploads.dev.evos.gg/web/check.png',
  account: 'https://uploads.dev.evos.gg/web/avatar.png',
  guest: 'https://uploads.dev.evos.gg/web/Backgorund.jpg',

  brokenLink: 'https://uploads.dev.evos.gg/web/broken-link.png',
  emailSent: 'https://uploads.dev.evos.gg/web/email-sent.png',
  inputEmail: 'https://uploads.dev.evos.gg/web/input-email.png',
  resetPassword: 'https://uploads.dev.evos.gg/web/reset-password.png',
  cupActive: 'https://uploads.dev.evos.gg/networks/cup_active.png',
  cupLight: 'https://uploads.dev.evos.gg/networks/cup_light.png',
  cupDark: 'https://uploads.dev.evos.gg/networks/cup_dark.png',
  metazoneActive: 'https://uploads.dev.evos.gg/networks/metazone_active.png',
  metazoneLight: 'https://uploads.dev.evos.gg/networks/metazone_light.png',
  metazoneDark: 'https://uploads.dev.evos.gg/networks/metazone_dark.png',
  shopActive: 'https://uploads.dev.evos.gg/networks/shop_active.png',
  shopLight: 'https://uploads.dev.evos.gg/networks/shop_light.png',
  shopDark: 'https://uploads.dev.evos.gg/networks/shop_dark.png',
  myActive: 'https://uploads.dev.evos.gg/networks/my_active.png',
  myLight: 'https://uploads.dev.evos.gg/networks/my_light.png',
  myDark: 'https://uploads.dev.evos.gg/networks/my_dark.png',
  mainActive: 'https://uploads.dev.evos.gg/networks/main_active.png',
  mainLight: 'https://uploads.dev.evos.gg/networks/main_light.png',
  mainDark: 'https://uploads.dev.evos.gg/networks/main_dark.png',
  homeActive: 'https://uploads.dev.evos.gg/networks/home_active.png',
  homeLight: 'https://uploads.dev.evos.gg/networks/home_light.png',
  homeDark: 'https://uploads.dev.evos.gg/networks/home_dark.png',
  userActive: 'https://uploads.dev.evos.gg/networks/user_active.png',
  userLight: 'https://uploads.dev.evos.gg/networks/user_light.png',
  userDark: 'https://uploads.dev.evos.gg/networks/user_dark.png',
  chevronRight: 'https://uploads.dev.evos.gg/web/circle-chevron-right.png',
  chevronRightWhite:
    'https://uploads.dev.evos.gg/web/circle-chevron-right-wht.png',
  tickEfc: 'https://uploads.dev.evos.gg/web/tick+icon+efc.png',
  tick: 'https://uploads.dev.evos.gg/web/tick+icon.png',
  verifyPhone: 'https://uploads.dev.evos.gg/web/verify-phone-image.png'
}

export default ASSET
