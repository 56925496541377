import ENVIRONMENT from '../constants/environment'
import ENVS from '../utils/environment'
import ASSET from '../constants/asset'
import { INetwork } from '@evos/general-particle/dist/interfaces/account'

const GetList = (env: ENVIRONMENT): Array<INetwork> => {
  return [
    {
      images: {
        light: ASSET.homeLight,
        dark: ASSET.homeDark,
        active: ASSET.homeActive
      },
      name: 'MyEVOS',
      link: ENVS[env].MY_FRAGMENT_URL
    },
    {
      images: {
        light: ASSET.metazoneLight,
        dark: ASSET.metazoneDark,
        active: ASSET.metazoneActive
      },
      name: 'METAZONE',
      link: ENVS[env].METAZONE_FRAGMENT_URL
    },
    {
      images: {
        light: ASSET.cupLight,
        dark: ASSET.cupDark,
        active: ASSET.cupActive
      },
      name: 'EFC',
      link: ENVS[env].CUP_FRAGMENT_URL
    },
    {
      images: {
        light: ASSET.shopLight,
        dark: ASSET.shopDark,
        active: ASSET.shopActive
      },
      name: 'SHOP',
      link: ENVS[env].SHOP_FRAGMENT_URL
    },
    {
      images: {
        light: ASSET.mainLight,
        dark: ASSET.mainDark,
        active: ASSET.mainActive
      },
      name: 'MAIN SITE',
      link: ENVS[env].MAIN_FRAGMENT_URL
    }
  ]
}

export default { GetList }
