import * as React from 'react'
import { IBaseProps } from '../../interfaces/base'
import API from '../../apis'
import { IButtonProps } from '../../shared-components/button'
import DefaultForm, { IField } from '../../shared-components/default-form'
import T from '../../utils/translation'
import {
  ICountry,
  IProvince,
  ICity,
  IAddress
} from '@evos/general-particle/dist/interfaces/account'
import { IResult } from '@evos/general-particle/dist/interfaces/api'

interface IProps
  extends IBaseProps,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  onSuccess?: (value: IAddress) => void
  onFailure?: (value?: string) => void
  mode?: 'add' | 'edit' | 'read'
  addressId?: number
}

interface IStates extends IAddress {
  countries?: Array<ICountry>
  provinces?: Array<IProvince>
  cities?: Array<ICity>
  isLoading?: boolean
  originalData?: IAddress
}

class AddressForm extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      ...({} as IAddress),
      countries: [],
      cities: [],
      provinces: []
    }
  }

  componentDidMount = () => {
    this.clearForm()
    const { mode, addressId, onFailure, env }: IProps = this.props
    this.getCountries()
    if (mode === 'edit' && addressId) {
      API.Address.GetById(env, addressId)
        .then((res: IAddress) => this.setState({ ...res, originalData: res }))
        .catch((err: { response: { data: IResult<IAddress> } }) => {
          onFailure &&
            onFailure(err.response.data.errors?.map((e) => e.message).join(','))
        })
    }
  }

  clearForm = () => {
    const address = {} as IAddress
    this.setState({ ...address })
  }

  getCountries = () => {
    const { env } = this.props
    API.Country.GetList(env).then((res?: Array<ICountry>) => {
      this.setState({ countries: res })
    })
  }

  onComplete = (fields: Array<IField>) => {
    const finalFields: IAddress = {} as IAddress
    fields.forEach((x) => {
      finalFields[x.name] = x.value
    })
    this.setState(
      {
        ...finalFields
      },
      () =>
        this.props.mode === 'edit'
          ? this.update(finalFields)
          : this.add(finalFields)
    )
  }

  onChange = ({ name: fieldName, value }: any) => {
    const { env } = this.props
    const { originalData, ...resFields } = this.state
    if (fieldName === 'country') {
      API.Province.GetList(env, value.id).then((res?: Array<IProvince>) => {
        let newProvince, newCity
        if (originalData && originalData.province) {
          newProvince = res?.find((p) => p.id === originalData.province?.id)
        }
        if (originalData && originalData.city && newProvince) {
          newCity = originalData.city
        }
        this.setState({
          ...resFields,
          country: value,
          provinces: res,
          province: newProvince,
          cities: [],
          city: newCity,
          originalData: {
            ...originalData,
            country: undefined
          } as IAddress
        })
      })
    } else if (fieldName === 'province') {
      if (value && value !== this.state.province) {
        API.City.GetList(env, value.id).then((res?: Array<ICity>) => {
          let newCity
          if (originalData && originalData.city) {
            newCity = res?.find((p) => p.id === originalData.city?.id)
          }
          this.setState({
            ...resFields,
            province: value,
            cities: res,
            city: newCity,
            originalData: {
              ...originalData,
              province: undefined
            } as IAddress
          })
        })
      }
    } else if (fieldName === 'city') {
      this.setState({
        ...resFields,
        city: value,
        originalData: {
          ...originalData,
          city: undefined
        } as IAddress
      })
    } else {
      this.setState({
        ...resFields,
        [fieldName]: value
      })
    }
  }

  add = (data: IAddress) => {
    const { env, onSuccess, onFailure }: IProps = this.props
    this.setState({ isLoading: true })
    API.Address.Add(env, data)
      .then((res: IAddress) => onSuccess && onSuccess(res))
      .catch((err: { response: { data: IResult<IAddress> } }) => {
        onFailure &&
          onFailure(err.response.data.errors?.map((e) => e.message).join(','))
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  update = (data: IAddress) => {
    const { env, onSuccess, onFailure, addressId }: IProps = this.props
    this.setState({ isLoading: true })
    if (addressId)
      API.Address.Update(env, addressId, { ...data, isActive: true })
        .then((res: IAddress) => onSuccess && onSuccess(res))
        .catch((err: { response: { data: IResult<IAddress> } }) => {
          onFailure &&
            onFailure(err.response.data.errors?.map((e) => e.message).join(','))
        })
        .finally(() => this.setState({ isLoading: false }))
  }

  render() {
    const { env, language, mode, style }: IProps = this.props
    const {
      cities,
      provinces,
      countries,
      isLoading,
      title,
      name,
      phoneNumber,
      country,
      province,
      city,
      addressLine,
      postalCode,
      isMainAddress
    } = this.state
    const FIELDS = [
      {
        label: T('address label', { locale: language }),
        placeholder: T('eg address label', { locale: language }),
        type: 'text',
        name: 'title',
        required: true,
        maxLength: 50,
        value: title,
        onChange: this.onChange
      },
      {
        label: T('receiver name', { locale: language }),
        placeholder: T('receiver name', { locale: language }),
        type: 'text',
        name: 'name',
        required: true,
        maxLength: 50,
        value: name,
        onChange: this.onChange
      },
      {
        label: T('phone number', { locale: language }),
        placeholder: T('input phone number', { locale: language }),
        type: 'number',
        name: 'phoneNumber',
        required: true,
        minLength: 8,
        value: phoneNumber,
        onChange: this.onChange
      },
      {
        label: T('select country', { locale: language }),
        placeholder: T('country name', { locale: language }),
        type: 'select',
        name: 'country',
        required: true,
        options: countries,
        onChange: this.onChange,
        value: country
      },
      {
        label: T('select province', { locale: language }),
        placeholder: T('province name', { locale: language }),
        type: 'select',
        name: 'province',
        required: true,
        options: provinces,
        onChange: this.onChange,
        isSearchable: true,
        value: province
      },
      {
        label: T('select city', { locale: language }),
        placeholder: T('city name', { locale: language }),
        type: 'select',
        name: 'city',
        required: true,
        options: cities,
        onChange: this.onChange,
        isSearchable: true,
        value: city
      },
      {
        label: T('address', { locale: language }),
        placeholder: T('full address', { locale: language }),
        type: 'textarea',
        name: 'addressLine',
        required: true,
        rows: 3,
        maxLength: 225,
        value: addressLine,
        onChange: this.onChange
      },
      {
        label: T('postal code', { locale: language }),
        type: 'number',
        name: 'postalCode',
        required: true,
        maxLength: 5,
        minLength: 5,
        size: 150,
        value: postalCode,
        onChange: this.onChange
      },
      {
        label: T('set as default', { locale: language }),
        type: 'checkbox',
        name: 'isMainAddress',
        required: false,
        value: isMainAddress,
        onChange: this.onChange
      }
    ] as Array<IField>

    const BUTTON = ({
      label: T(mode === 'add' ? 'save' : 'update', { locale: language }),
      isLoading: isLoading,
      disabled: isLoading,
      variant: 'outline',
      block: mode === 'edit'
    } as unknown) as IButtonProps

    return (
      <div>
        <DefaultForm
          env={env}
          fields={FIELDS}
          button={BUTTON}
          language={language}
          onComplete={this.onComplete}
          style={style}
        />
      </div>
    )
  }
}

export default AddressForm
