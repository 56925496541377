import * as React from 'react'
import { IRegister } from '../../interfaces/auth'
import API from '../../apis'
import { IBaseProps } from '../../interfaces/base'
import DefaultForm, { IField } from '../../shared-components/default-form'
import T from '../../utils/translation'
import { IButtonProps } from '../../shared-components/button'
import { IUser } from '@evos/general-particle/dist/interfaces/account'
import STORAGE from '../../constants/storage'
import GeneralParticle from '@evos/general-particle'
import ENVS from '../../utils/environment'

const { GetQuery } = GeneralParticle.Utils.URL
const { Set } = GeneralParticle.Utils.Storage

interface IProps extends IBaseProps {
  onSuccess?: (value?: IUser) => void
  onFailure?: (value?: string) => void
}

interface IStates extends IRegister {
  isLoading?: boolean
  fields: Array<IField>
}

class RegisterForm extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    const { language } = props
    this.state = {
      isLoading: false,
      fields: [
        {
          label: T('full name', { locale: language }),
          placeholder: T('enter your name', { locale: language }),
          type: 'text',
          name: 'fullName',
          required: true
        },
        {
          label: T('email', { locale: language }),
          placeholder: T('enter valid email', { locale: language }),
          type: 'email',
          name: 'email',
          required: true
        },
        {
          label: T('password', { locale: language }),
          placeholder: T('minimum character password', {
            locale: language
          }),
          type: 'password',
          name: 'password',
          minLength: 8,
          required: true,
          matchingRef: true
        },
        {
          label: T('confirm new password', { locale: language }),
          placeholder: T('minimum character password', {
            locale: language
          }),
          type: 'password',
          name: 'confirmpPassword',
          required: true,
          match: true,
          matchError: T('password not match', { locale: language })
        }
      ] as Array<IField>
    }
  }

  onComplete = (fields: Array<IField>) => {
    const { language } = this.props
    const finalFields: { [key: string]: any } = {}
    fields.forEach((x) => {
      if (x.name !== 'confirmPassword') finalFields[x.name] = x.value
    })
    finalFields.language = { id: language.toUpperCase() }
    this.setState(
      {
        ...finalFields,
        fields
      },
      () => this.register(finalFields)
    )
  }

  register = (data: IRegister) => {
    const { env, onSuccess, onFailure } = this.props
    this.setState({ isLoading: true })
    API.Auth.Register(
      env,
      data,
      (user: IUser) => {
        const ref = GetQuery('referrer') || document.referrer
        Set(
          STORAGE.referrer + ENVS[env].STORAGE_SUFFIX,
          ref,
          ENVS[env].STORAGE_DOMAIN
        )
        onSuccess && onSuccess(user)
      },
      (err) => {
        onFailure && onFailure(err)
      },
      () => {
        this.setState({ isLoading: false })
      }
    )
  }

  render() {
    const { isLoading } = this.state
    const { language, env } = this.props

    const BUTTON = ({
      label: T('register', { locale: language }),
      isLoading: isLoading,
      disabled: isLoading,
      variant: 'primary',
      block: true
    } as unknown) as IButtonProps

    return (
      <DefaultForm
        env={env}
        fields={this.state.fields}
        button={BUTTON}
        language={language}
        onComplete={this.onComplete}
      />
    )
  }
}

export default RegisterForm
