import ENVIRONMENT from '../constants/environment'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'
import {
  IProvider,
  IUser
} from '@evos/general-particle/dist/interfaces/account'

export default {
  GetRedirectUrl: (
    env: ENVIRONMENT,
    provider: string
  ): Promise<IResponse<{ redirectUrl: string }>> => {
    return Base(env).get(`/accounts/v1/public/auth/${provider}`)
  },
  GetCallback: (
    env: ENVIRONMENT,
    provider: string,
    value: string
  ): Promise<IResponse<IUser>> => {
    return Base(env).get(
      `/accounts/v1/public/auth/${provider}/callback?${value}`
    )
  },
  GetList: (env: ENVIRONMENT): Promise<IResponse<Array<IProvider>>> => {
    return Base(env).get('/accounts/v1/public/auth/providers')
  }
}
