import * as React from 'react'
import { IBaseProps } from '../../interfaces/base'
import Row from '../../shared-components/grid/row'
import Column from '../../shared-components/grid/column'
import Text from '../../shared-components/text'
import NetworkAPI from '../../apis/network'
import T from '../../utils/translation'
import { INetwork } from '@evos/general-particle/dist/interfaces/account'
import ASSET from '../../constants/asset'
import moduleStyles from '../../modules.style.css'
import styles from './index.style.css'

const { GetList } = NetworkAPI

interface IProps extends IBaseProps {
  height?: number
}

interface IStates {
  networks: Array<INetwork>
}

class GuestScreen extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      networks: []
    }
  }

  componentDidMount = () => {
    const networks = GetList(this.props.env)
    this.setState({ networks })
  }

  render() {
    const { language, height } = this.props
    const { networks } = this.state
    return (
      <Row fullWidth>
        <Column
          fullWidth
          style={{ backgroundImage: `url(${ASSET.guest})`, height }}
          className={`${moduleStyles.p2} ${styles.container}`}
        >
          <Row className={`${moduleStyles.mv2} ${moduleStyles.pt2}`}>
            <Text color='white' size={32} lineHeight={48} weight='bold'>
              {T('join now', { locale: language })}
            </Text>
          </Row>
          <Row>
            <Text color='white' size={18} lineHeight={27} weight='semibold'>
              {T('get access to our service', { locale: language })}
            </Text>
          </Row>
          {networks.map((n, key) => (
            <Row key={key} className={`${moduleStyles.pv2}`}>
              <img src={n.images.light} style={{ width: 24, height: 24 }} />
              <Text
                color='white'
                size={16}
                lineHeight={24}
                className={`${moduleStyles.pl2}`}
              >
                {n.name}
              </Text>
            </Row>
          ))}
        </Column>
      </Row>
    )
  }
}

export default GuestScreen
