import GeneralParticle from '@evos/general-particle'
import ENVIRONMENT from '../constants/environment'
import STORAGE from '../constants/storage'
import ENVS from '../utils/environment'

const { Get, Remove } = GeneralParticle.Utils.Storage

const Referrer = (env: ENVIRONMENT, callback: () => void) => {
  const referrer = Get(STORAGE.referrer + ENVS[env].STORAGE_SUFFIX)
  const { hostname } = window.location
  const splittedHostname = hostname.split('.')
  const tld =
    splittedHostname.length > 1
      ? `${splittedHostname[splittedHostname.length - 2]}.${
          splittedHostname[splittedHostname.length - 1]
        }`
      : hostname
  Remove(STORAGE.referrer + ENVS[env].STORAGE_SUFFIX, ENVS[env].STORAGE_DOMAIN)
  if (referrer && referrer.includes(tld) && !referrer.includes(hostname)) {
    window.location.href = referrer
  } else {
    callback()
  }
}

export default Referrer
