import '../../modules.style.css'

import * as React from 'react'
import styles from './index.style.css'

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: React.ReactNode
  justify?: 'start' | 'center' | 'end' | 'stretch'
  align?: 'start' | 'center' | 'end' | 'stretch'
  className?: string
  fullFlex?: boolean
  fullWidth?: boolean
  width?: number
}

const ColumnSharedComponent = ({
  children,
  justify,
  align,
  className,
  fullFlex,
  fullWidth,
  width,
  ...props
}: IProps) => {
  return (
    <div
      className={`${styles.column} ${
        justify ? styles[`justify-${justify}`] : ''
      } ${align ? styles[`align-${align}`] : ''} ${
        fullFlex ? styles['full-flex'] : ''
      } ${fullWidth ? styles['full-width'] : ''} ${className}`}
      style={{ width }}
      {...props}
    >
      {children}
    </div>
  )
}

ColumnSharedComponent.defaultProps = {
  justify: 'start',
  align: 'start',
  className: ''
}

export default ColumnSharedComponent
