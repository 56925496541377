import DATA from '../datas'
import ENVIRONMENT from '../constants/environment'
import DEFAULT_PREFERENCE from '../constants/preference'
import STORAGE from '../constants/storage'
import user from './user'
import { ICountry, IUser } from '@evos/general-particle/dist/interfaces/account'
import GeneralParticle from '@evos/general-particle'
import ENVS from '../utils/environment'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'

const { Set: SetStorage, Get: GetStorage } = GeneralParticle.Utils.Storage

const GetList = async (env: ENVIRONMENT): Promise<Array<ICountry>> => {
  let result = [DEFAULT_PREFERENCE]
  await Base(
    env,
    DATA.Language.GetList(env),
    (response: IResponse<Array<ICountry>>) => {
      if (
        response.data?.success &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        result = response.data.data
      }
    }
  )
  return result
}

const Set = async (
  env: ENVIRONMENT,
  language: ICountry,
  currentUser?: IUser
) => {
  SetStorage(
    STORAGE.language + ENVS[env].STORAGE_SUFFIX,
    language,
    ENVS[env].STORAGE_DOMAIN
  )
  const current = currentUser || (await user.GetCurrent(env))
  if (current) {
    const { email, fullName } = current
    user.Update(env, {
      email,
      fullName,
      language: {
        id: language.id
      } as ICountry
    } as IUser)
  }
}

const Get = (env: ENVIRONMENT): ICountry => {
  const language = GetStorage(STORAGE.language + ENVS[env].STORAGE_SUFFIX)
  return language || DEFAULT_PREFERENCE
}

const Key = (): string => {
  return STORAGE.language
}

export default {
  GetList,
  Get,
  Set,
  Key
}
