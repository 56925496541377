import ENVIRONMENT from '../constants/environment'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'
import { IUser } from '@evos/general-particle/dist/interfaces/account'

export default {
  GetCurrent: (env: ENVIRONMENT, req?: any): Promise<IResponse<IUser>> => {
    return Base(env, req).get(
      `/accounts/v1/private/users/?timestamp=${new Date().getTime()}`
    )
  },
  Update: (env: ENVIRONMENT, data: IUser): Promise<IResponse<IUser>> => {
    return Base(env).put(`/accounts/v1/private/users`, { ...data })
  },
  VerifyDuplicatePhoneNumber: (env: ENVIRONMENT, value: string): Promise<any> =>
    Base(env).get(
      `/accounts/v1/private/users/is-phone-number-duplicate/${value}`
    ),
  UpdateEmail: (env: ENVIRONMENT, email: string): Promise<IResponse<any>> => {
    return Base(env).put(`/accounts/v1/private/users/change-email`, { email })
  }
}
