import DATA from '../datas'
import ENVIRONMENT from '../constants/environment'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'
import { IPointLog } from '@evos/general-particle/dist/interfaces/account'

const GetList = async (
  env: ENVIRONMENT,
  req?: any
): Promise<Array<IPointLog> | undefined> => {
  let result: any
  await Base(
    env,
    DATA.Point.GetList(env, req),
    (response: IResponse<Array<IPointLog>>) => {
      result = response.data?.data
    }
  )
  return result
}

export default {
  GetList
}
