import * as React from 'react'
import styles from './index.style.css'
import ASSET from '../../constants/asset'
import Input from '../input'
import Text from '../text'
import T from '../../utils/translation'
import Row from '../../shared-components/grid/row'
import Column from '../../shared-components/grid/column'
import { IBaseProps } from '../../interfaces/base'
import moduleStyles from '../../modules.style.css'

interface IProps extends IBaseProps {
  label: string
  options?: Array<[]>
  onChange: (value: any) => void
  onClick?: (overlayShown: boolean, name?: string) => void
  name?: string
  value?: any
  error?: string
  isSearchable?: boolean
  placeholder?: string
}

interface IStates {
  keyword?: string
  error?: string
  isVisible?: boolean
  options?: Array<[]>
}

class Select extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      isVisible: false,
      keyword: '',
      options: this.props.options || []
    }
  }

  componentDidMount = () => {
    if (this.props.value) {
      this.action(this.props.name, this.props.value)
    }
  }

  componentWillReceiveProps(nextProps: IProps) {
    this.setState({ error: nextProps.error, options: nextProps.options || [] })
    if (nextProps.value !== this.props.value) {
      this.action(nextProps.name, nextProps.value)
    }

    if (nextProps.options !== this.props.options) {
      this.setState({
        options: nextProps.options
      })
    }
  }

  onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { options }: IProps = this.props
    const filters = options?.filter((item: any) => {
      const name = item.name.toLowerCase()
      if (name.includes(e.target.value.toLowerCase())) return item
    })

    this.setState({
      [e.target.name]: e.target.value,
      options: filters
    })
  }

  action = (name: string | undefined, obj: any) => {
    this.props.onChange({ name, value: obj })
    this.onHide()
  }

  show = () => {
    const { onClick, name }: IProps = this.props
    this.setState({ isVisible: true }, () => {
      if (onClick) {
        onClick(true, name)
      }
    })
  }

  onHide = () => {
    const { onClick, name }: IProps = this.props
    this.setState({ isVisible: false, keyword: '', error: undefined }, () => {
      if (onClick) {
        onClick(false, name)
      }
    })
  }

  render() {
    const {
      label,
      name,
      error,
      isSearchable,
      placeholder,
      language,
      env,
      value
    } = this.props
    const { isVisible, keyword, options }: IStates = this.state
    const bottomSheet = () => {
      return (
        <Row>
          <Column>
            <div className={styles.overlay} onClick={this.onHide} />
            <Column className={styles.sheet} fullWidth>
              <Row align='center' className={styles.header} fullWidth>
                <img
                  src={ASSET.arrowBack}
                  className={styles.arrow_icon}
                  onClick={this.onHide}
                />
                <Text size={14}>{label}</Text>
              </Row>
              {isSearchable && (
                <Column className={styles.search_container} fullWidth>
                  <Text size={14}>{T('search', { locale: language })}</Text>
                  <Input
                    env={env}
                    language={language}
                    style={{ marginTop: 5 }}
                    required={false}
                    type='text'
                    value={keyword}
                    name='keyword'
                    onChange={this.onSearch}
                  />
                </Column>
              )}
              <Column style={{ maxHeight: 350, overflowY: 'auto' }} fullWidth>
                {options &&
                  options.map((obj: any, key: number) => {
                    return (
                      <Row
                        key={key}
                        className={moduleStyles.pv1}
                        fullFlex
                        fullWidth
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.action(name, obj)}
                      >
                        <Text
                          size={14}
                          lineHeight={24}
                          color={
                            value && obj.id === value.id
                              ? 'primary-blue'
                              : 'primary-black'
                          }
                        >
                          {obj.name}
                        </Text>
                      </Row>
                    )
                  })}
              </Column>
            </Column>
          </Column>
        </Row>
      )
    }

    return (
      <Row fullWidth>
        <Column fullWidth>
          <Row
            style={{ cursor: 'pointer' }}
            onClick={this.show}
            fullWidth
            fullFlex
          >
            <Column fullFlex>
              <Text size={14}>{label}</Text>
              {this.props.value?.name ? (
                <Row fullWidth className={moduleStyles.mt1}>
                  <Text size={14} color='primary-blue'>
                    {this.props.value?.name}
                  </Text>
                </Row>
              ) : (
                <Row fullWidth className={moduleStyles.mt1}>
                  <Text size={14} color='secondary-black'>
                    {placeholder}
                  </Text>
                </Row>
              )}
            </Column>
            <button className={styles.btn_select}>
              <img src={ASSET.rightSign} />
            </button>
          </Row>
          {error && (
            <Text color='red' size={12}>
              {error}
            </Text>
          )}
          {isVisible && bottomSheet()}
        </Column>
      </Row>
    )
  }
}

export default Select
