import * as React from 'react'
import Skeleton from 'react-loading-skeleton'
import { IUser } from '@evos/general-particle/dist/interfaces/account'
import GeneralParticle from '@evos/general-particle'
import UserAPI from '../../apis/user'
import API from '../../apis'
import { IBaseProps } from '../../interfaces/base'
import Row from '../../shared-components/grid/row'
import Column from '../../shared-components/grid/column'
import ASSET from '../../constants/asset'
import Text from '../../shared-components/text'
import CommonStyles from '../../modules.style.css'
import styles from './index.style.css'
import T from '../../utils/translation'
import Button from '../../shared-components/button'
import { IMetadata } from '@evos/general-particle/dist/interfaces/shop'

const { GetCurrent } = UserAPI
const {
  Numberize,
  Capitalize,
  ConvertCardNumber
} = GeneralParticle.Utils.Format

interface IProps
  extends IBaseProps,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  /*
   * the size (width) of this component
   * if undefined it will streched to fit device width
   */
  size?: number
  /**
   * action click member
   */
  onClickMember?: () => void
  /**
   * action click emp
   */
  onClickEMP?: () => void
  /**
   * action click add phone
   */
  onClickAddPhone?: () => void
  /**
   * action click verify
   */
  onVerify?: () => void
  /**
   * apply theme
   */
  theme?: 'dark' | 'light'
}
interface IStates {
  user?: IUser
  metadatas?: Array<IMetadata>
}
export default class AccountBadgeComponent extends React.Component<
  IProps,
  IStates
> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      user: undefined
    }
  }

  componentDidMount = async () => {
    const { env, theme } = this.props
    document.documentElement.setAttribute('theme', theme || 'light')
    API.Metadata.GetList(env, 'VERIFY_PHONE_REWARD', (res) =>
      this.setState({ metadatas: res })
    )
    const user = await GetCurrent(env)
    this.setState({
      user
    })
  }

  render() {
    const { user, metadatas } = this.state
    const {
      size,
      language,
      onClickMember,
      onClickEMP,
      onClickAddPhone,
      theme,
      className,
      onVerify
    } = this.props
    const ICON_WIDTH = 48
    const SMALL_ICON_WIDTH = 15
    const PHONE_EMP = metadatas && metadatas.length > 0 ? metadatas[0].value : 0
    const width = size || window.innerWidth
    const ICON_RIGHT =
      theme === 'dark' ? ASSET.chevronRightWhite : ASSET.chevronRight
    const ICON_TICK = theme === 'dark' ? ASSET.tickEfc : ASSET.tick

    /* sucessfully get result from API */
    if (user && user.isEmailValid) {
      return (
        <Row
          className={`${styles['account-badge']} ${CommonStyles.pv1} ${className}`}
          fullWidth
        >
          <Column fullWidth>
            <Row className={`${CommonStyles.p2}`} align='center'>
              <Column>
                <img
                  src={ASSET.account}
                  style={{ width: ICON_WIDTH, height: ICON_WIDTH }}
                  className={CommonStyles.mr2}
                />
              </Column>
              <Column>
                <Text weight='bold' size={18} color='inherit' lineHeight={25}>
                  {Capitalize(user.fullName)}
                </Text>
              </Column>
            </Row>
            <Row fullWidth>
              <Row
                fullWidth
                align='center'
                className={`${styles['card-container']} ${CommonStyles.ml2}`}
                onClick={onClickMember}
              >
                <Column fullFlex>
                  <Text size={12} className={styles.label} lineHeight={18}>
                    {T('membership', { locale: language })}
                  </Text>
                  <Text
                    size={14}
                    weight='semibold'
                    color='inherit'
                    lineHeight={21}
                  >
                    {user.membershipPackage?.name || '-'}
                  </Text>
                </Column>
                <Column>
                  <img
                    src={ICON_RIGHT}
                    style={{
                      width: SMALL_ICON_WIDTH,
                      height: SMALL_ICON_WIDTH
                    }}
                  />
                </Column>
              </Row>
              <Row
                fullWidth
                align='center'
                className={`${styles['card-container']} ${CommonStyles.mr2}`}
                onClick={onClickEMP}
              >
                <Column fullFlex>
                  <Text size={12} className={styles.label} lineHeight={18}>
                    {T('my emp', { locale: language })}
                  </Text>
                  <Text
                    size={14}
                    weight='semibold'
                    color='inherit'
                    lineHeight={21}
                  >
                    {`${user?.point ? Numberize(user?.point, '.') : '0'} EMP`}
                  </Text>
                </Column>
                <Column>
                  <img
                    src={ICON_RIGHT}
                    style={{
                      width: SMALL_ICON_WIDTH,
                      height: SMALL_ICON_WIDTH
                    }}
                  />
                </Column>
              </Row>
            </Row>
            <Row fullWidth>
              <Column fullWidth className={`${CommonStyles.p2}`}>
                <Row>
                  <Text size={14} className={styles.label} lineHeight={21}>
                    {T('email', { locale: language })}
                  </Text>
                </Row>
                <Row align='center'>
                  <Text size={16} lineHeight={24} color='inherit'>
                    {user.email || '-'}
                  </Text>
                  {user.isEmailValid && (
                    <img
                      src={ICON_TICK}
                      style={{
                        width: SMALL_ICON_WIDTH,
                        height: SMALL_ICON_WIDTH
                      }}
                      className={CommonStyles.ml1}
                    />
                  )}
                </Row>
              </Column>
            </Row>
            <Row fullWidth>
              <Column fullWidth className={`${CommonStyles.p2}`}>
                <Row>
                  <Text size={14} className={styles.label} lineHeight={21}>
                    Membership ID
                  </Text>
                </Row>
                <Row align='center'>
                  <Text size={16} lineHeight={24} color='inherit'>
                    {ConvertCardNumber(user.id)}
                  </Text>
                </Row>
              </Column>
            </Row>
            <Row fullWidth>
              <Column
                fullWidth
                className={`${CommonStyles.ph2} ${CommonStyles.pt1} ${CommonStyles.pb2}`}
              >
                <Text size={14} className={styles.label} lineHeight={21}>
                  {T('phone number', { locale: language })}
                </Text>
                <Row
                  fullWidth
                  className={styles['phone-container']}
                  align='center'
                >
                  <Text size={16} lineHeight={24} color='inherit'>
                    {user.phoneNumber || '-'}
                  </Text>
                  {!user.isPhoneNumberValid && (
                    <Button
                      variant='transparent'
                      onClick={onClickAddPhone}
                      style={{ padding: 0 }}
                    >
                      <Text
                        size={14}
                        color='inherit'
                        lineHeight={21}
                        align='right'
                        className={`${styles['fill-phone']}`}
                      >
                        <u>{T('fill phone number', { locale: language })}</u>{' '}
                        {PHONE_EMP ? (
                          <strong className={styles['phone-emp']}>
                            (+{PHONE_EMP} EMP)
                          </strong>
                        ) : undefined}
                      </Text>
                    </Button>
                  )}
                </Row>
              </Column>
            </Row>
          </Column>
        </Row>
      )
    } else if (user && !user.isEmailValid) {
      return (
        <Row fullWidth>
          <Column fullWidth>
            <Row fullWidth justify='center'>
              <Text
                className={`${CommonStyles.pv1}`}
                color={theme === 'light' ? 'primary-black' : 'white'}
              >
                {T('your account is not verified', { locale: language })}
              </Text>
            </Row>
            <Row fullWidth>
              <Button block onClick={onVerify}>
                {T('verify email', { locale: language })}
              </Button>
            </Row>
          </Column>
        </Row>
      )
    }

    /* processing / failed to fetch API */
    return (
      <Row>
        <Column>
          <Row>
            <Skeleton
              height={ICON_WIDTH}
              width={ICON_WIDTH}
              className={CommonStyles.mr2}
            />
          </Row>
          <Row>
            <Skeleton height={48} width={width} className={CommonStyles.mb1} />
          </Row>
          <Row>
            <Skeleton height={21} width={width} />
          </Row>
        </Column>
      </Row>
    )
  }
}
