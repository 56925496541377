import * as React from 'react'
import API from '../../apis'
import { IBaseProps } from '../../interfaces/base'
import T from '../../utils/translation'
import { IResult } from '@evos/general-particle/dist/interfaces/api'
import { IProvider } from '@evos/general-particle/dist/interfaces/account'
import Row from '../../shared-components/grid/row'
import Column from '../../shared-components/grid/column'
import Button from '../../shared-components/button'
import styles from './index.style.css'
import moduleStyles from '../../modules.style.css'
import Spinner from '../../shared-components/spinner'
import Text from '../../shared-components/text'
import Colors from '../../constants/color'
import STORAGE from '../../constants/storage'
import GeneralParticle from '@evos/general-particle'
import ENVS from '../../utils/environment'

const { Capitalize } = GeneralParticle.Utils.Format
const { GetQuery } = GeneralParticle.Utils.URL
const { Set } = GeneralParticle.Utils.Storage

interface IProps extends IBaseProps {
  onSuccess?: (value?: string) => void
  onFailure?: (value?: string) => void
}

interface IStates {
  isLoading?: boolean
  authProviderList?: Array<IProvider>
}

class AuthProviderComponent extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      isLoading: false,
      authProviderList: undefined
    }
  }

  componentDidMount = async () => {
    const { env } = this.props
    const list = await API.Provider.GetList(env)
    this.setState({ authProviderList: list })
  }

  onProcessAuth = async (provider: string) => {
    const { env, onSuccess, onFailure } = this.props
    this.setState({ isLoading: true })
    await API.Provider.GetRedirectUrl(env, provider)
      .then((res) => {
        const ref = GetQuery('referrer') || document.referrer
        Set(
          STORAGE.referrer + ENVS[env].STORAGE_SUFFIX,
          ref,
          ENVS[env].STORAGE_DOMAIN
        )
        onSuccess && onSuccess(res?.redirectUrl)
      })
      .catch((err: { response: { data: IResult<IProvider> } }) => {
        onFailure &&
          onFailure(err.response.data.errors?.map((e) => e.message).join(','))
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  render() {
    const { isLoading, authProviderList } = this.state
    const { language } = this.props

    return (
      <Row fullWidth>
        <Column fullWidth>
          {authProviderList &&
            authProviderList.map((item) => (
              <Row fullWidth key={item.id} className={`${moduleStyles.mv1}`}>
                <Button
                  block
                  isLoading={isLoading}
                  disabled={isLoading}
                  variant='outline'
                  onClick={() => this.onProcessAuth(item.name)}
                  className={`${moduleStyles.ph3}`}
                  style={{ borderColor: Colors.tertiaryBlack, borderRadius: 2 }}
                >
                  <div className={styles.textContainer}>
                    <img
                      src={item.image}
                      width={30}
                      height={30}
                      className={styles.facebookIcon}
                    />
                    {isLoading && <Spinner style={{ marginRight: 10 }} />}
                    <Text size={16} lineHeight={20} color='primary-black'>
                      {T('continue with provider', {
                        locale: language,
                        provider: Capitalize(item.name)
                      })}
                    </Text>
                  </div>
                </Button>
              </Row>
            ))}
        </Column>
      </Row>
    )
  }
}

export default AuthProviderComponent
