import ENVIRONMENT from '../constants/environment'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'
import { IMetadata } from '@evos/general-particle/dist/interfaces/shop'

export default {
  GetList: (
    env: ENVIRONMENT,
    types: string
  ): Promise<IResponse<Array<IMetadata>>> => {
    return Base(env).get(`/general/v1/public/metadata?types=${types}`)
  }
}
