import * as React from 'react'
import ASSET from '../../constants/asset'
import { IBaseProps } from '../../interfaces/base'
import styles from './index.style.css'

interface IProps extends IBaseProps {
  name: string
  onChange: (event: any) => void
  checked?: boolean
  style?: any
}

class Input extends React.Component<IProps> {
  onChange = (event: any) => {
    this.props.onChange(event)
  }

  render() {
    const { style, name, checked }: IProps = this.props
    return (
      <div className={styles.round}>
        <input
          type='checkbox'
          id='checkbox'
          style={...style}
          name={name}
          onChange={this.onChange}
        />
        <label htmlFor='checkbox' />
        {checked && (
          <div className={styles.check}>
            <img src={ASSET.checklist} className={styles.check_icon} />
          </div>
        )}
      </div>
    )
  }
}

export default Input
