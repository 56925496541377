import * as React from 'react'
import GeneralParticle from '@evos/general-particle'
import API from '../../apis'
import { IBaseProps } from '../../interfaces/base'
import DefaultForm, { IField } from '../../shared-components/default-form'
import T from '../../utils/translation'
import { IButtonProps } from '../../shared-components/button'
import { IVerifyEmail } from '../../interfaces/auth'
import Row from '../../shared-components/grid/row'
import Col from '../../shared-components/grid/column'
import Text from '../../shared-components/text'
import styles from './index.style.css'
import ASSET from '../../constants/asset'
import ENVS from '../../utils/environment'

const { Utils } = GeneralParticle

interface IProps extends IBaseProps {
  onSuccess?: (value?: string) => void
  onFailure?: (value?: string) => void
  content?: React.ReactNode
  data: IVerifyEmail
}

interface IStates extends IVerifyEmail {
  isLoading?: boolean
}

class SendEmailForm extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      ...({} as IVerifyEmail)
    }
  }

  componentDidMount = () => {
    this.setState({ ...({ ...this.props.data } as IVerifyEmail) })
  }

  onComplete = (fields: Array<IField>) => {
    const { env } = this.props
    const finalFields: IVerifyEmail = {} as IVerifyEmail
    fields.forEach((x) => {
      finalFields[x.name] = x.value
    })
    this.setState(
      {
        ...finalFields
      },
      () =>
        this.sendEmail({
          ...finalFields,
          referrer:
            this.props.data?.referrer ||
            Utils.Crypt.Encrypt('', ENVS[env].MY_FRAGMENT_URL)
        })
    )
  }

  sendEmail = (data: IVerifyEmail) => {
    const { env, onSuccess, onFailure } = this.props
    this.setState({ isLoading: true })
    API.Auth.SendEmail(
      env,
      {
        ...data,
        email: encodeURIComponent(data.email)
      },
      () => {
        onSuccess && onSuccess(data.email)
      },
      (err) => {
        onFailure && onFailure(err)
      },
      () => {
        this.setState({ isLoading: false })
      }
    )
  }

  render() {
    const { isLoading, email } = this.state
    const { language, env, content } = this.props
    const FIELDS = [
      {
        label: T('email', { locale: language }),
        type: 'email',
        name: 'email',
        required: true,
        value: email,
        maxLength: 50
      }
    ] as Array<IField>

    const BUTTON = ({
      label: T('save and verify email', { locale: language }),
      isLoading: isLoading,
      disabled: isLoading,
      variant: 'primary',
      block: true
    } as unknown) as IButtonProps

    return (
      <Row fullWidth>
        <Col fullWidth>
          <Row
            fullWidth
            justify='center'
            className={`${styles.imageContainer}`}
          >
            <Col>
              <img src={ASSET.inputEmail} style={{ width: 100, height: 100 }} />
            </Col>
          </Row>
          <Row fullWidth className={`${styles.textContainer}`}>
            <Col>
              {content || (
                <Text lineHeight={24} size={16} weight='regular'>
                  {T('please input active email', { locale: language })}
                </Text>
              )}
            </Col>
          </Row>
          <Row fullWidth>
            <Col fullWidth>
              <DefaultForm
                env={env}
                fields={FIELDS}
                button={BUTTON}
                language={language}
                onComplete={this.onComplete}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default SendEmailForm
