import DATA from '../datas'
import ENVIRONMENT from '../constants/environment'
import DEFAULT_PREFERENCE from '../constants/preference'
import STORAGE from '../constants/storage'
import { ICountry } from '@evos/general-particle/dist/interfaces/account'
import GeneralParticle from '@evos/general-particle'
import ENVS from '../utils/environment'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'

const { Set: SetStorage, Get: GetStorage } = GeneralParticle.Utils.Storage

const GetList = async (env: ENVIRONMENT): Promise<Array<ICountry>> => {
  let result = [DEFAULT_PREFERENCE]
  await Base(
    env,
    DATA.Country.GetList(env),
    (response: IResponse<Array<ICountry>>) => {
      if (
        response.data?.success &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        result = response.data.data
      }
    }
  )
  return result
}

const Set = (country: ICountry, env: ENVIRONMENT) => {
  SetStorage(
    STORAGE.country + ENVS[env].STORAGE_SUFFIX,
    country,
    ENVS[env].STORAGE_DOMAIN
  )
}

const Get = (env: ENVIRONMENT): ICountry => {
  const country = GetStorage(STORAGE.country + ENVS[env].STORAGE_SUFFIX)
  return country || DEFAULT_PREFERENCE
}

const Key = (): string => {
  return STORAGE.country
}

export default {
  GetList,
  Get,
  Set,
  Key
}
