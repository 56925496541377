import ENVIRONMENT from '../constants/environment'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'
import { IProvince } from '@evos/general-particle/dist/interfaces/account'

export default {
  GetList: (
    env: ENVIRONMENT,
    countryId: string
  ): Promise<IResponse<Array<IProvince>>> => {
    return Base(env).get(`/v1/provinces/?countryCode=${countryId}`)
  }
}
