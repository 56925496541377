import * as React from 'react'
import { IForgotPassword, IRegister } from '../../interfaces/auth'
import API from '../../apis'
import { IBaseProps } from '../../interfaces/base'
import DefaultForm, { IField } from '../../shared-components/default-form'
import T from '../../utils/translation'
import { IButtonProps } from '../../shared-components/button'
import Row from '../../shared-components/grid/row'
import Col from '../../shared-components/grid/column'
import styles from './index.style.css'
import ASSET from '../../constants/asset'
import Text from '../../shared-components/text'

interface IProps extends IBaseProps {
  onSuccess?: (value?: string) => void
  onFailure?: (value?: string) => void
}

interface IStates extends IRegister {
  isLoading?: boolean
  isSentEmail?: string
  errorMessage?: string
}

class ForgotPasswordForm extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      isLoading: false,
      isSentEmail: undefined
    }
  }

  onComplete = (fields: Array<IField>) => {
    const finalFields: { [key: string]: any } = {}
    fields.forEach((x) => {
      finalFields[x.name] = x.value
    })
    this.forgotPassword(finalFields as IForgotPassword)
  }

  forgotPassword = (data: IForgotPassword) => {
    const { env, onSuccess, onFailure } = this.props
    this.setState({ isLoading: true })
    API.Auth.ForgotPassword(
      env,
      data,
      (data: { email: string }) => {
        this.setState({ isSentEmail: data.email })
        onSuccess && onSuccess(data.email)
      },
      (err) => {
        this.setState({ errorMessage: err }, () => {
          onFailure && onFailure(err)
        })
        setTimeout(() => this.setState({ errorMessage: '' }), 5000)
      },
      () => {
        this.setState({ isLoading: false })
      }
    )
  }

  render() {
    const { isLoading, isSentEmail } = this.state
    const { language, env } = this.props
    const FIELDS = [
      {
        label: T('enter registered email', { locale: language }),
        placeholder: T('please input valid email', { locale: language }),
        type: 'email',
        name: 'email',
        required: true
      }
    ] as Array<IField>

    const BUTTON = ({
      isLoading: isLoading,
      label: T('submit', { locale: language }),
      disabled: isLoading,
      variant: 'primary',
      block: true
    } as unknown) as IButtonProps

    return (
      <Row fullWidth>
        {isSentEmail ? (
          <Col fullWidth>
            <Row
              fullWidth
              justify='center'
              className={`${styles.imageContainer}`}
            >
              <img src={ASSET.emailSent} style={{ width: 100, height: 100 }} />
            </Row>
            <Row fullWidth className={`${styles.textContainer}`}>
              <Text size={18} lineHeight={27} weight='semibold'>
                {T('verification email has been sent', {
                  locale: language
                })}
              </Text>
            </Row>
            <Row fullWidth justify='center'>
              <Text size={16} lineHeight={24} weight='regular' align='center'>
                {T('we have sent verification email', {
                  locale: language
                })}
              </Text>
            </Row>
            <Row fullWidth justify='center'>
              <Text size={16} lineHeight={24} weight='semibold' align='center'>
                &nbsp;
                {`${isSentEmail}.`}
                &nbsp;
              </Text>
            </Row>
            <Row fullWidth className={`${styles.textContainer}`}>
              <Text size={16} lineHeight={24} weight='regular' align='center'>
                {T('please check your inbox', {
                  locale: language
                })}
              </Text>
            </Row>
          </Col>
        ) : (
          <Col fullWidth>
            <Row
              fullWidth
              justify='center'
              className={`${styles.imageContainer}`}
            >
              <img src={ASSET.inputEmail} style={{ width: 100, height: 100 }} />
            </Row>
            <Row fullWidth>
              <DefaultForm
                env={env}
                fields={FIELDS}
                button={BUTTON}
                additionalErrMsg={this.state.errorMessage}
                language={language}
                onComplete={this.onComplete}
              />
            </Row>
          </Col>
        )}
      </Row>
    )
  }
}

export default ForgotPasswordForm
