/* eslint-disable no-unused-expressions */
import * as React from 'react'
import AddressAPI from '../../apis/address'
import UserAPI from '../../apis/user'
import { IBaseProps } from '../../interfaces/base'
import { IAddress } from '@evos/general-particle/dist/interfaces/account'
import AddressBadge from '../address-badge'
import Row from '../../shared-components/grid/row'
import Column from '../../shared-components/grid/column'
import Button from '../../shared-components/button'
import Text from '../../shared-components/text'
import T from '../../utils/translation'
import moduleStyles from '../../modules.style.css'

const { GetList, SetMain } = AddressAPI
const { GetToken } = UserAPI

interface IProps
  extends IBaseProps,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  /*
   * function triggered when user choose to set as default from action sheet
   */
  onDefault?: (value: IAddress, error: boolean, message: string) => void
  /*
   * function triggered when click on add button
   * add button will be displayed when user dont have any address yet
   */
  onAdd?: () => void
  /*
   * function triggered when click edit button on the right size or when user choose to edit from action sheet
   * if only onEdit defined then render as right button
   * if onEdit and onDelete defined then render three dots button with action sheet
   */
  onEdit?: (value: IAddress) => void
  /*
   * function triggered when user choose to delete from action sheet
   */
  onDelete?: (value: IAddress) => void
  /*
   * the size (width) of this component
   * if undefined it will streched to fit device width
   */
  size?: number
}

interface IStates {
  addresses?: Array<IAddress>
  overlayExists: boolean
}

export default class AddressListComponent extends React.Component<
  IProps,
  IStates
> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      addresses: [],
      overlayExists: false
    }
  }

  componentDidMount = async () => {
    const { env } = this.props
    const addresses = await GetList(env)
    this.setState({
      addresses
    })
  }

  setAsDefault = async (address: IAddress) => {
    const { env, onDefault } = this.props
    await SetMain(env, address)

    const addresses = this.state.addresses?.map((a) => ({
      ...a,
      isMainAddress: a.id === address.id
    }))

    this.setState({ addresses }, () => {
      if (onDefault) onDefault(address, false, '')
    })
  }

  delete = (address: IAddress) => {
    const { onDelete } = this.props
    const { addresses } = this.state
    const newAddresses = addresses?.filter((x) => x.id !== address.id)
    this.setState({ addresses: newAddresses }, () => {
      if (onDelete) onDelete(address)
    })
  }

  render() {
    const { onAdd, onEdit, env, language, size, style } = this.props
    const { overlayExists } = this.state
    const width = size || window.innerWidth

    const renderItem = (data: IAddress) => {
      return (
        <AddressBadge
          env={env}
          language={language}
          size={width}
          address={data}
          onDefault={this.setAsDefault}
          onEdit={onEdit}
          onDelete={this.delete}
          onToggleActionSheet={(overlayExists: boolean) => {
            this.setState({ overlayExists })
          }}
        />
      )
    }

    const { addresses } = this.state
    return (
      <Column
        fullWidth
        className={`${moduleStyles.ph2}`}
        style={{
          ...style,
          overflowY: overlayExists ? 'hidden' : 'auto',
          overflowX: 'hidden'
        }}
      >
        {!!GetToken(env) && (
          <Row fullWidth className={`${moduleStyles.mb2}`} width={width - 32}>
            <Button
              variant='outline'
              block
              className={`${moduleStyles.ph2} ${moduleStyles.pv1}`}
              onClick={onAdd}
            >
              <Text color='primary-blue' size={14} lineHeight={21}>
                {T('add new address', { locale: language })}
              </Text>
            </Button>
          </Row>
        )}
        {addresses?.map((a, key) => {
          return (
            <Row
              fullWidth
              justify='center'
              className={moduleStyles.mb2}
              key={key}
            >
              {renderItem(a)}
            </Row>
          )
        })}
      </Column>
    )
  }
}
