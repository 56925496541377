import ENVIRONMENT from '../constants/environment'
import { IAddress } from '@evos/general-particle/dist/interfaces/account'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'
import Base from './base'

export default {
  GetList: (env: ENVIRONMENT): Promise<IResponse<Array<IAddress>>> => {
    return Base(env).get(`/accounts/v1/private/address`)
  },
  GetById: (
    env: ENVIRONMENT,
    addressId: number
  ): Promise<IResponse<IAddress>> => {
    return Base(env).get(`/accounts/v1/private/address/${addressId}`)
  },
  Add: (env: ENVIRONMENT, data: IAddress): Promise<any> => {
    return Base(env).post(`/accounts/v1/private/address`, { ...data })
  },
  Update: (
    env: ENVIRONMENT,
    addressId: number,
    data: IAddress
  ): Promise<any> => {
    return Base(env).put(`/accounts/v1/private/address/${addressId}`, {
      ...data
    })
  },
  Delete: (env: ENVIRONMENT, addressId: string): Promise<any> => {
    return Base(env).delete(`/accounts/v1/private/address/${addressId}`)
  },
  GetMain: (env: ENVIRONMENT): Promise<IResponse<Array<IAddress>>> => {
    return Base(env).get('/accounts/v1/private/address/?isMainAddress=true')
  },
  SetMain: (env: ENVIRONMENT, addressId: string): Promise<any> => {
    return Base(env).put(
      `/accounts/v1/private/address/set-as-main-address/${addressId}`
    )
  }
}
