import DATA from '../datas'
import ENVIRONMENT from '../constants/environment'
import { IAddress } from '@evos/general-particle/dist/interfaces/account'
import UserAPI from './user'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'

const { GetToken } = UserAPI

const GetList = async (
  env: ENVIRONMENT
): Promise<Array<IAddress> | undefined> => {
  const token = GetToken(env)
  let result
  if (token) {
    await Base(
      env,
      DATA.Address.GetList(env),
      (response: IResponse<Array<IAddress>>) => {
        result = response.data?.data
      }
    )
  }
  return result
}

const GetById = async (
  env: ENVIRONMENT,
  id: number
): Promise<IAddress | undefined> => {
  const token = GetToken(env)
  let result
  if (token) {
    await Base(
      env,
      DATA.Address.GetById(env, id),
      (response: IResponse<IAddress>) => {
        result = response.data?.data
      }
    )
  }
  return result
}

const SetMain = async (
  env: ENVIRONMENT,
  address: IAddress
): Promise<IAddress | undefined> => {
  const token = GetToken(env)
  let result
  if (token) {
    await Base(
      env,
      DATA.Address.SetMain(env, address.id.toString()),
      (response: IResponse<Array<IAddress>>) => {
        result =
          response.data?.data && response.data.data?.length > 0
            ? (response.data.data[0] as IAddress)
            : ({} as IAddress)
      }
    )
  }
  return result
}

const GetMain = async (env: ENVIRONMENT): Promise<IAddress | undefined> => {
  const token = GetToken(env)
  let result
  if (token) {
    await Base(
      env,
      DATA.Address.GetMain(env),
      (response: IResponse<Array<IAddress>>) => {
        result =
          response.data?.data && response.data.data?.length > 0
            ? (response.data.data[0] as IAddress)
            : ({} as IAddress)
      }
    )
  }
  return result
}

const Add = async (
  env: ENVIRONMENT,
  data: IAddress
): Promise<IAddress | undefined> => {
  data.isActive = true
  let result
  await Base(
    env,
    DATA.Address.Add(env, data),
    (response: IResponse<IAddress>) => {
      result = response.data?.data
    }
  )
  return result
}

const Update = async (
  env: ENVIRONMENT,
  addressId: number,
  data: IAddress
): Promise<IAddress | undefined> => {
  data.isActive = true
  let result
  await Base(
    env,
    DATA.Address.Update(env, addressId, data),
    (response: IResponse<IAddress>) => {
      result = response.data?.data
    }
  )
  return result
}

const Delete = async (
  env: ENVIRONMENT,
  addressId: string
): Promise<IAddress | undefined> => {
  let result
  await Base(
    env,
    DATA.Address.Delete(env, addressId),
    (response: IResponse<IAddress>) => {
      result = response.data?.data
    }
  )
  return result
}

export default {
  GetList,
  GetById,
  SetMain,
  GetMain,
  Add,
  Update,
  Delete
}
