import DATA from '../datas'
import ENVIRONMENT from '../constants/environment'
import { IProvince } from '@evos/general-particle/dist/interfaces/account'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'

const GetList = async (
  env: ENVIRONMENT,
  countryId: string
): Promise<Array<IProvince> | undefined> => {
  let result
  await Base(
    env,
    DATA.Province.GetList(env, countryId),
    (response: IResponse<Array<IProvince>>) => {
      result = response.data?.data
    }
  )
  return result
}

export default {
  GetList
}
