import * as React from 'react'
import API from '../../apis'
import { IBaseProps } from '../../interfaces/base'
import DefaultForm, { IField } from '../../shared-components/default-form'
import T from '../../utils/translation'
import { IResult } from '@evos/general-particle/dist/interfaces/api'
import { IUser } from '@evos/general-particle/dist/interfaces/account'
import { IButtonProps } from '../../shared-components/button'
import UserAPI from '../../apis/user'

interface IProps extends IBaseProps {
  onSuccess?: (value: IUser) => void
  onFailure?: (value?: string) => void
}

interface IStates extends IUser {
  isLoading?: boolean
}

class SettingForm extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      ...({} as IUser)
    }
  }

  componentDidMount = () => {
    const { env, onFailure } = this.props
    UserAPI.GetCurrent(env)
      .then((res: IUser) => this.setState({ ...res }))
      .catch((err: { response: { data: IResult<IUser> } }) => {
        onFailure &&
          onFailure(err.response.data.errors?.map((e) => e.message).join(','))
      })
  }

  onComplete = (fields: Array<IField>) => {
    const finalFields: IUser = {} as IUser
    fields.forEach((x) => {
      finalFields[x.name] = x.value
    })
    this.setState(
      {
        ...finalFields
      },
      () => this.update(finalFields)
    )
  }

  update = (data: IUser) => {
    const { env, onSuccess, onFailure } = this.props
    this.setState({ isLoading: true })
    API.User.Update(
      env,
      data,
      (user: IUser) => {
        onSuccess && onSuccess(user)
      },
      (err) => {
        onFailure && onFailure(err)
      },
      () => {
        this.setState({ isLoading: false })
      }
    )
  }

  render() {
    const { isLoading, fullName, email, language: languageState } = this.state
    const { language, env } = this.props
    const FIELDS = [
      {
        label: T('full name', { locale: language }),
        type: 'text',
        name: 'fullName',
        required: true,
        value: fullName,
        maxLength: 50
      },
      {
        label: T('email', { locale: language }),
        type: 'email',
        name: 'email',
        required: true,
        value: email
      },
      {
        label: T('language', { locale: language }),
        type: 'text',
        name: 'languageId',
        value: languageState?.id || '-',
        readonly: true
      }
    ] as Array<IField>

    const BUTTON = ({
      label: T('save', { locale: language }),
      isLoading: isLoading,
      disabled: isLoading,
      variant: 'primary',
      block: true
    } as unknown) as IButtonProps

    return (
      <DefaultForm
        env={env}
        fields={FIELDS}
        button={BUTTON}
        language={language}
        onComplete={this.onComplete}
      />
    )
  }
}

export default SettingForm
