import * as React from 'react'
import { IUser } from '@evos/general-particle/dist/interfaces/account'
import API from '../../apis'
import { IBaseProps } from '../../interfaces/base'
import DefaultForm, { IField } from '../../shared-components/default-form'
import T from '../../utils/translation'
import { IButtonProps } from '../../shared-components/button'
import Row from '../../shared-components/grid/row'
import Col from '../../shared-components/grid/column'
import Text from '../../shared-components/text'
import styles from './index.style.css'
import ASSET from '../../constants/asset'
import UserAPI from '../../apis/user'

interface IProps extends IBaseProps {
  onSuccess?: (value?: string) => void
  onFailure?: (value?: string) => void
}

interface IStates extends IUser {
  isLoading?: boolean
  user?: IUser
}

const { GetCurrent } = UserAPI

class ChangeEmailForm extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      user: undefined,
      ...({} as IUser)
    }
  }

  componentDidMount = async () => {
    const { env } = this.props
    const user = await GetCurrent(env)
    this.setState({
      user
    })
  }

  onComplete = (fields: Array<IField>) => {
    const finalFields: IUser = {} as IUser
    fields.forEach((x) => {
      finalFields[x.name] = x.value
    })
    this.setState(
      {
        ...finalFields
      },
      () => this.updateEmail(finalFields)
    )
  }

  updateEmail = (data: IUser) => {
    const { env, onSuccess, onFailure } = this.props
    this.setState({ isLoading: true })
    API.User.UpdateEmail(
      env,
      data.email,
      () => {
        onSuccess && onSuccess(data.email)
      },
      (err) => {
        onFailure && onFailure(err)
      },
      () => {
        this.setState({ isLoading: false })
      }
    )
  }

  render() {
    const { isLoading, user, email } = this.state
    const { language, env } = this.props
    const FIELDS = [
      {
        label: T('new email', { locale: language }),
        type: 'email',
        name: 'email',
        required: true,
        maxLength: 50,
        value: email
      }
    ] as Array<IField>

    const BUTTON = ({
      label: T('save and verify email', { locale: language }),
      isLoading: isLoading,
      disabled: isLoading,
      variant: 'primary',
      block: true
    } as unknown) as IButtonProps

    return (
      <Row fullWidth>
        <Col fullWidth>
          <Row
            fullWidth
            justify='center'
            className={`${styles.imageContainer}`}
          >
            <Col>
              <img src={ASSET.inputEmail} style={{ width: 100, height: 100 }} />
            </Col>
          </Row>
          <Row fullWidth className={`${styles.textContainer}`}>
            <Col>
              <Text lineHeight={24} size={16} weight='regular'>
                {T('please enter your new email', { locale: language })}
              </Text>
            </Col>
          </Row>
          <Row fullWidth className={`${styles.textContainer}`}>
            <Col>
              <Text lineHeight={21} size={14} weight='regular'>
                {T('current email', { locale: language })}
              </Text>
              <Text lineHeight={27} size={16} weight='semibold'>
                {user?.email}
              </Text>
            </Col>
          </Row>
          <Row fullWidth>
            <Col fullWidth>
              <DefaultForm
                env={env}
                fields={FIELDS}
                button={BUTTON}
                language={language}
                onComplete={this.onComplete}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default ChangeEmailForm
