/* eslint-disable react/no-did-update-set-state */
import * as React from 'react'
import Skeleton from 'react-loading-skeleton'
import AddressAPI from '../../apis/address'
import { IBaseProps } from '../../interfaces/base'
import { IAddress } from '@evos/general-particle/dist/interfaces/account'
import Button from '../../shared-components/button'
import T from '../../utils/translation'
import Text from '../../shared-components/text'
import Row from '../../shared-components/grid/row'
import Column from '../../shared-components/grid/column'
import moduleStyles from '../../modules.style.css'
import styles from './index.style.css'
import ASSET from '../../constants/asset'
import ActionSheet from '../../shared-components/action-sheet'

const { GetMain, Delete } = AddressAPI

interface IProps extends IBaseProps {
  /*
   * function triggered when user choose to set as default from action sheet
   */
  onDefault?: (value: IAddress) => void
  /*
   * function triggered when click on add button
   * add button will be displayed when user dont have any address yet
   */
  onAdd?: () => void
  /*
   * function triggered when click edit button on the right size or when user choose to edit from action sheet
   * if only onEdit defined then render as right button
   * if onEdit and onDelete defined then render three dots button with action sheet
   */
  onEdit?: (value: IAddress) => void
  /*
   * function triggered when user choose to delete from action sheet
   */
  onDelete?: (value: IAddress) => void
  /*
   * the size (width) of this component
   * if undefined it will streched to fit device width
   */
  size?: number
  /*
   * main data used for component
   */
  address?: IAddress
  /*
   * function triggered when AS toggled
   */
  onToggleActionSheet?: (ASShown: boolean) => void
}

interface IStates {
  address?: IAddress
  AS3DotsVisible: boolean
  ASDeleteVisible: boolean
  isDeleting: boolean
}

class AddressBadgeComponent extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      address: undefined,
      AS3DotsVisible: false,
      ASDeleteVisible: false,
      isDeleting: false
    }
  }

  componentDidMount = async () => {
    const { env, address: addressProps } = this.props

    /* use address props if exist, otherwise get main address */
    const address = addressProps || (await GetMain(env))
    this.setState({
      address
    })
  }

  toggleAS3DotsModal = () => {
    this.setState(
      (state) => ({ AS3DotsVisible: !state.AS3DotsVisible }),
      () => {
        this.toggleAS()
      }
    )
  }

  toggleASDeletesModal = () => {
    this.setState(
      (state) => ({ ASDeleteVisible: !state.ASDeleteVisible }),
      () => {
        this.toggleAS()
      }
    )
  }

  toggleAS = () => {
    const { onToggleActionSheet } = this.props
    const { AS3DotsVisible, ASDeleteVisible } = this.state
    const ASShown = AS3DotsVisible || ASDeleteVisible
    if (onToggleActionSheet) onToggleActionSheet(ASShown)
  }

  delete = () => {
    const { env, onDelete } = this.props
    const { address } = this.state
    if (address) {
      this.setState(
        {
          isDeleting: true
        },
        async () => {
          await Delete(env, address.id.toString())
          if (onDelete) onDelete(address)
          this.setState({ isDeleting: false }, () =>
            this.toggleASDeletesModal()
          )
        }
      )
    }
  }

  render() {
    const { address, AS3DotsVisible, ASDeleteVisible, isDeleting } = this.state
    const {
      size,
      language,
      onAdd,
      onEdit,
      onDelete,
      onDefault,
      address: addressProps
    } = this.props
    const COMPONENT_HEIGHT = 95
    const width = size || window.innerWidth
    const ADDRESS = addressProps || address

    /* sucessfully get result from API */
    /* user already set main address */
    if (ADDRESS && Object.keys(ADDRESS).length > 0) {
      return (
        <Row align='stretch' width={width}>
          <Column
            fullWidth
            className={`${styles['address-badge']} ${
              ADDRESS.isMainAddress ? styles['main-address'] : ''
            } ${moduleStyles.pv2}`}
            width={width - 32}
          >
            <Row align='center' fullWidth>
              <Column fullWidth className={moduleStyles.ph2}>
                <Text size={14} lineHeight={21} weight='semibold'>
                  {ADDRESS.title}
                </Text>
              </Column>
              {ADDRESS.isMainAddress && onEdit && (onDelete || onDefault) && (
                <Text
                  size={10}
                  lineHeight={15}
                  weight='semibold'
                  color='white'
                  background='primary-blue'
                  align='center'
                  className={`${moduleStyles.mr1} ${styles['main-badge']}`}
                >
                  {T('default', { locale: language })}
                </Text>
              )}
              {onEdit && (onDelete || onDefault) && (
                <Button
                  onClick={(e: any) => {
                    e.stopPropagation()
                    this.toggleAS3DotsModal()
                  }}
                  variant='transparent'
                >
                  <img src={ASSET.option} style={{ width: 18, height: 18 }} />
                </Button>
              )}
            </Row>
            <Row className={moduleStyles.ph2}>
              <Text
                size={12}
                lineHeight={18}
              >{`${ADDRESS.name} (${ADDRESS.phoneNumber})`}</Text>
            </Row>
            <Row className={moduleStyles.ph2}>
              <Text
                size={12}
                lineHeight={18}
              >{`${ADDRESS.addressLine} ${ADDRESS.city?.name} ${ADDRESS.province?.name} ${ADDRESS.country?.name} ${ADDRESS.postalCode}`}</Text>
            </Row>
          </Column>
          {onEdit && !(onDelete && onDefault) && (
            <Button onClick={() => onEdit(ADDRESS)} className={moduleStyles.p1}>
              <img src={ASSET.pencil} style={{ width: 16, height: 16 }} />
            </Button>
          )}
          <ActionSheet
            visible={AS3DotsVisible}
            width={width}
            onClose={() => this.toggleAS3DotsModal()}
          >
            <Row className={moduleStyles.pt2}>
              <Column className={moduleStyles.p2} fullWidth>
                {onEdit && (
                  <Row fullWidth>
                    <Button
                      variant='transparent'
                      block
                      className={moduleStyles.p2}
                      onClick={() => onEdit(ADDRESS)}
                    >
                      <Text size={16} lineHeight={24}>
                        {T('edit', { locale: language })}
                      </Text>
                    </Button>
                  </Row>
                )}
                {onDefault && (
                  <Row fullWidth>
                    <Button
                      variant='transparent'
                      block
                      className={moduleStyles.p2}
                      onClick={() => {
                        onDefault(ADDRESS)
                        this.toggleAS3DotsModal()
                      }}
                    >
                      <Text size={16} lineHeight={24}>
                        {T('set as default', { locale: language })}
                      </Text>
                    </Button>
                  </Row>
                )}
                {onDelete && (
                  <Row fullWidth>
                    <Button
                      variant='transparent'
                      className={moduleStyles.p2}
                      block
                      onClick={() => {
                        this.toggleAS3DotsModal()
                        this.toggleASDeletesModal()
                      }}
                    >
                      <Text size={16} lineHeight={24}>
                        {T('delete', { locale: language })}
                      </Text>
                    </Button>
                  </Row>
                )}
              </Column>
            </Row>
          </ActionSheet>
          <ActionSheet
            visible={ASDeleteVisible}
            width={width}
            onClose={() => this.toggleASDeletesModal()}
          >
            <Row className={moduleStyles.pt2}>
              <Column className={moduleStyles.p2} fullWidth>
                <Row fullWidth>
                  <Text size={16} lineHeight={24} weight='bold'>
                    {T('delete address', { locale: language })}
                  </Text>
                </Row>
                <hr />
                <Row fullWidth>
                  <Text size={16} lineHeight={24}>
                    {T('do you want to delete this address', {
                      locale: language
                    })}
                  </Text>
                </Row>
                <Row className={moduleStyles.mt2} justify='end' fullWidth>
                  <Button
                    variant='transparent'
                    className={moduleStyles.p2}
                    onClick={() => this.toggleASDeletesModal()}
                  >
                    <Text size={14} lineHeight={21} weight='semibold'>
                      {T('keep address', { locale: language })}
                    </Text>
                  </Button>
                  <Button
                    variant='transparent'
                    className={moduleStyles.p2}
                    onClick={() => this.delete()}
                  >
                    <Text size={14} lineHeight={21} weight='semibold'>
                      {T(isDeleting ? 'wait' : 'delete', {
                        locale: language
                      })}
                    </Text>
                  </Button>
                </Row>
              </Column>
            </Row>
          </ActionSheet>
        </Row>
      )
    }

    /* sucessfully get result from API */
    /* user havent set main address yet */
    if (address && Object.keys(address).length === 0) {
      return (
        <Row justify='center' fullWidth>
          <Column align='center' fullWidth>
            <Row className={moduleStyles.mb1} justify='center' fullWidth>
              <Text size={12} lineHeight={18}>
                {T('you dont have address', { locale: language })}
              </Text>
            </Row>
            {onAdd && (
              <Row fullWidth>
                <Button variant='outline' onClick={onAdd} block>
                  {T('add new address', { locale: language })}
                </Button>
              </Row>
            )}
          </Column>
        </Row>
      )
    }

    /* processing / failed to fetch API */
    return <Skeleton height={COMPONENT_HEIGHT} width={width} />
  }
}

export default AddressBadgeComponent
