import * as React from 'react'
import Cotter from 'cotter'
import API from '../../apis'
import { IBaseProps } from '../../interfaces/base'
import Row from '../../shared-components/grid/row'
import Col from '../../shared-components/grid/column'
import ENV from '../../utils/environment'
import T from '../../utils/translation'
import styles from './index.style.css'
import ASSET from '../../constants/asset'
import Text from '../../shared-components/text'

interface IProps extends IBaseProps {
  onSuccess?: () => void
  onFailure?: (value?: string) => void
  width?: number
  height?: number
}

interface IState {
  isSubmitted: boolean
}

class VerifyPhoneComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      isSubmitted: false
    }
  }

  componentDidMount() {
    const { env } = this.props
    API.User.GetCurrent(env).then((res) =>
      this.waitCotter(res?.email || '', false)
    )
  }

  onBegin = async (payload: any) => {
    const { env } = this.props
    const res = await API.User.VerifyDuplicatePhoneNumber(
      env,
      payload.identifier
    )
    if (res === null) this.setState({ isSubmitted: true })
    return res
  }

  waitCotter = (email: string, captchaEnabled: boolean) => {
    const { env, language, onSuccess, onFailure } = this.props
    const cotter = new Cotter(ENV[env].COTTER(email, captchaEnabled, env))
    cotter
      .signInWithOTP(this.onBegin)
      .showPhoneForm()
      .then(async (response: any) => {
        const { data } = await API.Auth.VerifyUser(env, response)
        if (data && data.success && onSuccess) {
          onSuccess()
        }
      })
      .catch((error: any) => {
        if (error && error.msg && typeof error.msg === 'string' && onFailure) {
          onFailure(error.msg)
        } else if (onFailure) {
          onFailure(T('something went wrong', { locale: language }))
        }
      })
  }

  render() {
    const { width, height, language } = this.props
    return (
      <Row fullWidth align='center'>
        <Col fullWidth align='center'>
          <Row
            fullWidth
            justify='center'
            className={`${styles.imageContainer}`}
          >
            <img src={ASSET.verifyPhone} style={{ width: 100, height: 100 }} />
          </Row>
          <Row fullWidth className={`${styles.textContainer}`}>
            <Text size={14} lineHeight={21}>
              {T('verify phone', { locale: language })}
            </Text>
          </Row>
          <div
            id='verify-phone-number'
            className={`${styles.verifyPhoneNumber}`}
            style={{ width: width || 320, height: height || 275 }}
          />
        </Col>
      </Row>
    )
  }
}

export default VerifyPhoneComponent
