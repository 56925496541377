import GeneralParticle from '@evos/general-particle'
import * as React from 'react'
import API from '../../apis'
import { IBaseProps } from '../../interfaces/base'
import Row from '../../shared-components/grid/row'
import Column from '../../shared-components/grid/column'
import Text from '../../shared-components/text'
import ASSET from '../../constants/asset'
import T from '../../utils/translation'
import styles from './index.style.css'

const { Encrypt } = GeneralParticle.Utils.Crypt

interface IProps
  extends IBaseProps,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  activeNetwork: string
  profileUrl: string
}
export default class NetworkBarComponent extends React.Component<IProps> {
  render() {
    const { activeNetwork, profileUrl, env, language } = this.props
    const networks = API.Network.GetList(env)
    const my = networks.find((x) => x.name === 'MyEVOS')
    const isLoggedIn = !!API.User.GetToken(env)
    return (
      <Row>
        {[
          ...networks.filter((x) => !['MAIN SITE'].includes(x.name)),
          {
            name: isLoggedIn ? 'PROFILE' : 'LOGIN',
            link: isLoggedIn
              ? profileUrl
              : my?.link +
                'login' +
                (my?.link.includes(window.location.host)
                  ? ''
                  : '?referrer=' + Encrypt('', window.location.href)),
            images: {
              active: ASSET.userActive,
              light: ASSET.userLight,
              dark: ASSET.userDark
            }
          }
        ].map((n) => {
          return (
            <Column
              key={n.link}
              justify='center'
              align='center'
              fullWidth
              onClick={() => (window.location.href = n.link)}
              className={`${styles.network} ${
                activeNetwork === n.name ? styles.active : styles.inactive
              }`}
            >
              <img
                src={activeNetwork === n.name ? n.images.active : n.images.dark}
                width={24}
                height={24}
              />
              <Text
                color={activeNetwork === n.name ? 'primary-blue' : 'gray'}
                size={10}
                lineHeight={15}
              >
                {['PROFILE', 'LOGIN'].includes(n.name)
                  ? T(n.name.toLowerCase(), { locale: language }).toUpperCase()
                  : n.name === 'MyEVOS'
                  ? T('home', { locale: language }).toUpperCase()
                  : n.name}
              </Text>
            </Column>
          )
        })}
      </Row>
    )
  }
}
