import Address from './address'
import Auth from './auth'
import City from './city'
import Country from './country'
import Language from './language'
import Network from './network'
import Package from './package'
import Point from './point'
import Provider from './provider'
import Province from './province'
import User from './user'
import Metadata from './metadata'

export default {
  Address,
  Auth,
  City,
  Country,
  Language,
  Network,
  Package,
  Point,
  Provider,
  Province,
  User,
  Metadata
}
