import ENVIRONMENT from '../constants/environment'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'
import { ICity } from '@evos/general-particle/dist/interfaces/account'

export default {
  GetList: (
    env: ENVIRONMENT,
    proviceId: number
  ): Promise<IResponse<Array<ICity>>> => {
    return Base(env).get(`/v1/cities/?provinceID=${proviceId}`)
  }
}
