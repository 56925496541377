import DATA from '../datas'
import ENVIRONMENT from '../constants/environment'
import {
  ILogin,
  IRegister,
  IForgotPassword,
  IResetPassword,
  IChangePassword,
  IVerifyEmail
} from '../interfaces/auth'
import STORAGE from '../constants/storage'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'
import { IUser } from '@evos/general-particle/dist/interfaces/account'
import GeneralParticle from '@evos/general-particle'
import ENVS from '../utils/environment'
import Referrer from '../utils/referrer'
import Base from './base'

const { Set, Remove } = GeneralParticle.Utils.Storage

const Login = (
  env: ENVIRONMENT,
  provider: string,
  data: ILogin,
  onSuccess?: (data: IUser) => any,
  onFailed?: (error?: string) => any,
  onCallback?: (res: IUser) => any,
  res?: any
) => {
  Base(
    env,
    DATA.Auth.Login(env, provider, data),
    (response: IResponse<IUser>) => {
      if (response.data?.success && response.data?.data) {
        Set(
          STORAGE.token + ENVS[env].STORAGE_SUFFIX,
          response.data.data?.token,
          ENVS[env].STORAGE_DOMAIN,
          res
        )
        onSuccess && onSuccess(response.data.data)
      } else {
        onFailed &&
          onFailed(response.data?.errors?.map((x) => x.message).join(','))
      }
    },
    (err) => {
      onFailed && onFailed(err)
    },
    (final) => {
      onCallback && onCallback(final)
    }
  )
}

const Register = (
  env: ENVIRONMENT,
  data: IRegister,
  onSuccess?: (data: IUser) => any,
  onFailed?: (error?: string) => any,
  onCallback?: (res: IUser) => any,
  res?: any
) => {
  Base(
    env,
    DATA.Auth.Register(env, data),
    (response: IResponse<IUser>) => {
      if (response.data?.success && response.data?.data) {
        Set(
          STORAGE.token + ENVS[env].STORAGE_SUFFIX,
          response.data.data?.token,
          ENVS[env].STORAGE_DOMAIN,
          res
        )
        onSuccess && onSuccess(response.data.data)
      } else {
        onFailed &&
          onFailed(response.data?.errors?.map((x) => x.message).join(','))
      }
    },
    (err) => {
      onFailed && onFailed(err)
    },
    (final) => {
      onCallback && onCallback(final)
    }
  )
}

const Logout = (env: ENVIRONMENT, res?: any) => {
  Remove(
    STORAGE.token + ENVS[env].STORAGE_SUFFIX,
    ENVS[env].STORAGE_DOMAIN,
    res
  )
}

const ForgotPassword = (
  env: ENVIRONMENT,
  data: IForgotPassword,
  onSuccess?: (data: { email: string }) => any,
  onFailed?: (error?: string) => any,
  onCallback?: (res: { email: string }) => any
) => {
  Base(
    env,
    DATA.Auth.ForgotPassword(env, data),
    (response: IResponse<{ email: string }>) => {
      if (response.data?.success && response.data?.data) {
        onSuccess && onSuccess(response.data.data)
      } else {
        onFailed &&
          onFailed(response.data?.errors?.map((x) => x.message).join(','))
      }
    },
    (err) => {
      onFailed && onFailed(err)
    },
    (final) => {
      onCallback && onCallback(final)
    }
  )
}

const ResetPassword = (
  env: ENVIRONMENT,
  data: IResetPassword,
  onSuccess?: (data: boolean) => any,
  onFailed?: (error?: string) => any,
  onCallback?: (res: boolean) => any
) => {
  Base(
    env,
    DATA.Auth.ResetPassword(env, data),
    (response: IResponse<boolean>) => {
      if (response.data?.success) {
        onSuccess && onSuccess(response.data?.success)
      } else {
        onFailed &&
          onFailed(response.data?.errors?.map((x) => x.message).join(','))
      }
    },
    (err) => {
      onFailed && onFailed(err)
    },
    (final) => {
      onCallback && onCallback(final)
    }
  )
}

const ChangePassword = (
  env: ENVIRONMENT,
  data: IChangePassword,
  onSuccess?: (data: boolean) => any,
  onFailed?: (error?: string) => any,
  onCallback?: (res: boolean) => any
) => {
  Base(
    env,
    DATA.Auth.ChangePassword(env, data),
    (response: IResponse<boolean>) => {
      if (response.data?.success) {
        onSuccess && onSuccess(response.data?.success)
      } else {
        onFailed &&
          onFailed(response.data?.errors?.map((x) => x.message).join(','))
      }
    },
    (err) => {
      onFailed && onFailed(err)
    },
    (final) => {
      onCallback && onCallback(final)
    }
  )
}

const ValidateSignature = (
  env: ENVIRONMENT,
  signature: string,
  userId: string,
  timestamp: string,
  onSuccess?: (data: boolean) => any,
  onFailed?: (error?: string) => any,
  onCallback?: (res: boolean) => any
) => {
  Base(
    env,
    DATA.Auth.ValidateSignature(env, signature, userId, timestamp),
    (response: IResponse<boolean>) => {
      if (response.data?.success) {
        onSuccess && onSuccess(response.data?.success)
      } else {
        onFailed &&
          onFailed(response.data?.errors?.map((x) => x.message).join(','))
      }
    },
    (err) => {
      onFailed && onFailed(err)
    },
    (final) => {
      onCallback && onCallback(final)
    }
  )
}

const VerifyUser = (env: ENVIRONMENT, response: any) =>
  DATA.Auth.VerifyUser(env, response)

const UseReferrer = (env: ENVIRONMENT, callback: () => void) => {
  Referrer(env, callback)
}

const SendEmail = (
  env: ENVIRONMENT,
  data: IVerifyEmail,
  onSuccess?: (data: boolean) => any,
  onFailed?: (error?: string) => any,
  onCallback?: (res: { email: string }) => any
) => {
  Base(
    env,
    DATA.Auth.SendEmail(env, data),
    (response: IResponse<boolean>) => {
      if (response.data?.success) {
        onSuccess && onSuccess(response.data?.success)
      } else {
        onFailed &&
          onFailed(response.data?.errors?.map((x) => x.message).join(','))
      }
    },
    (err) => {
      onFailed && onFailed(err)
    },
    (final) => {
      onCallback && onCallback(final)
    }
  )
}

const VerifiedEmailUser = (
  env: ENVIRONMENT,
  data: IVerifyEmail,
  onSuccess?: (data: boolean) => any,
  onFailed?: (error?: string) => any,
  onCallback?: (res: { email: string }) => any
) => {
  Base(
    env,
    DATA.Auth.VerifiedEmailUser(env, data),
    (response: IResponse<boolean>) => {
      if (response.data?.success) {
        onSuccess && onSuccess(response.data.success)
      } else {
        onFailed &&
          onFailed(response.data?.errors?.map((x) => x.message).join(','))
      }
    },
    (err) => {
      onFailed && onFailed(err)
    },
    (final) => {
      onCallback && onCallback(final)
    }
  )
}

const ValidateSignatureEmail = (
  env: ENVIRONMENT,
  signature: string,
  userId: string,
  timestamp: string,
  email: string,
  onSuccess?: (data: string) => any,
  onFailed?: (error?: string) => any,
  onCallback?: (res: boolean) => any
) => {
  Base(
    env,
    DATA.Auth.ValidateSignatureEmail(env, signature, userId, timestamp, email),
    (response: IResponse<{ token: string }>) => {
      if (response.data?.success && response.data?.data) {
        onSuccess && onSuccess(response.data.data.token)
      } else {
        onFailed &&
          onFailed(response.data?.errors?.map((x) => x.message).join(','))
      }
    },
    (err) => {
      onFailed && onFailed(err)
    },
    (final) => {
      onCallback && onCallback(final)
    }
  )
}

export default {
  Login,
  Logout,
  Register,
  ForgotPassword,
  ResetPassword,
  ChangePassword,
  ValidateSignature,
  VerifyUser,
  UseReferrer,
  SendEmail,
  VerifiedEmailUser,
  ValidateSignatureEmail
}
