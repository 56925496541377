import ENVIRONMENT from '../constants/environment'

const cotterApiKeyStag = '03ae2e48-5b6a-4bc1-ae42-dba9388ccd4c'
const cotterApiKeyProd = '9d62fd79-14aa-4663-8ae5-6965ec7fade1'

const COTTER = (
  email: string,
  captchaEnabled: boolean,
  env: ENVIRONMENT
): any => ({
  CaptchaRequired: captchaEnabled,
  ApiKeyID: env === 'production' ? cotterApiKeyProd : cotterApiKeyStag,
  Type: 'PHONE',
  ContainerID: 'verify-phone-number',
  CountryCode: ['+62'],
  AdditionalFields: [
    {
      label: 'email',
      name: 'email',
      type: 'hidden',
      initial_value: email
    }
  ],
  IdentifierField: 'phone',
  Styles: {
    form_container: {
      backgroundColor: '#F4F4F4'
    },
    pin_input: {
      borderWidth: 1,
      borderColor: '#D7D7D7',
      borderStyle: 'solid',
      backgroundColor: '#fff',
      width: 48,
      height: 56
    },
    input_text_container: {
      borderWidth: 1,
      borderColor: '#D7D7D7',
      borderStyle: 'solid',
      backgroundColor: '#fff'
    },
    country_code: {
      backgroundColor: '#fff',
      color: '#000'
    },
    input_label: {
      fontSize: 14
    },
    input_text: {
      '&:placeholder-shown': {
        color: '#f00'
      },
      backgroundColor: '#fff',
      width: '100%'
    },
    input_text_p: {
      marginRight: -15
    },
    error: {
      color: '#d30007'
    },
    button_container: {
      backgroundColor: '#0B3886',
      borderStyle: 'solid',
      borderColor: '#0B3886',
      borderWidth: 2
    }
  }
})

const ENVS = {
  local: {
    API_URL: 'https://api-gtw.dev.evos.gg/',
    SHOP_FRAGMENT_URL: 'http://localhost:3001/',
    METAZONE_FRAGMENT_URL: 'http://localhost:3000/',
    CUP_FRAGMENT_URL: 'http://localhost:3002/',
    MAIN_FRAGMENT_URL: 'http://localhost',
    MY_FRAGMENT_URL: 'http://localhost:3004/',
    STORAGE_DOMAIN: 'localhost',
    STORAGE_SUFFIX: '_local',
    COTTER
  },
  development: {
    API_URL: 'https://api-gtw.dev.evos.gg/',
    SHOP_FRAGMENT_URL: 'https://shop.dev.evos.gg/',
    METAZONE_FRAGMENT_URL: 'https://metazone.dev.evos.gg/',
    CUP_FRAGMENT_URL: 'https://cup.dev.evos.gg/',
    MAIN_FRAGMENT_URL: 'https://dev.evos.gg/',
    MY_FRAGMENT_URL: 'https://my.dev.evos.gg/',
    STORAGE_DOMAIN: '.dev.evos.gg',
    STORAGE_SUFFIX: '_dev',
    COTTER
  },
  staging: {
    API_URL: 'https://api-gtw.stag.evos.gg/',
    SHOP_FRAGMENT_URL: 'https://shop.stag.evos.gg/',
    METAZONE_FRAGMENT_URL: 'https://metazone.stag.evos.gg/',
    CUP_FRAGMENT_URL: 'https://cup.stag.evos.gg/',
    MAIN_FRAGMENT_URL: 'https://stag.evos.gg/',
    MY_FRAGMENT_URL: 'https://my.stag.evos.gg/',
    STORAGE_DOMAIN: '.stag.evos.gg',
    STORAGE_SUFFIX: '_stag',
    COTTER
  },
  production: {
    API_URL: 'https://api-gtw.evos.gg/',
    SHOP_FRAGMENT_URL: 'https://shop.evos.gg/',
    METAZONE_FRAGMENT_URL: 'https://metazone.evos.gg/',
    CUP_FRAGMENT_URL: 'https://cup.evos.gg/',
    MAIN_FRAGMENT_URL: 'https://evos.gg/',
    MY_FRAGMENT_URL: 'https://my.evos.gg/',
    STORAGE_DOMAIN: '.evos.gg',
    STORAGE_SUFFIX: '',
    COTTER
  }
}

export default ENVS
