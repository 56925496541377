import * as React from 'react'
import { IChangePassword, IRegister } from '../../interfaces/auth'
import API from '../../apis'
import { IBaseProps } from '../../interfaces/base'
import DefaultForm, { IField } from '../../shared-components/default-form'
import T from '../../utils/translation'
import { IButtonProps } from '../../shared-components/button'
import Row from '../../shared-components/grid/row'
import Col from '../../shared-components/grid/column'
import styles from './index.style.css'
import ASSET from '../../constants/asset'

interface IProps extends IBaseProps {
  onSuccess?: (data: boolean) => void
  onFailure?: (value?: string) => void
}

interface IStates extends IRegister {
  isLoading?: boolean
  fields?: Array<IField>
}

class ChangePasswordForm extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    const { language } = props
    this.state = {
      isLoading: false,
      fields: [
        {
          label: T('current password', { locale: language }),
          placeholder: T('minimum character password', { locale: language }),
          type: 'password',
          name: 'currentPassword',
          minLength: 8,
          required: true,
          title: T('enter current password', { locale: language })
        },
        {
          label: T('new password', { locale: language }),
          placeholder: T('minimum character password', { locale: language }),
          type: 'password',
          name: 'newPassword',
          minLength: 8,
          required: true,
          matchingRef: true,
          title: T('enter new password', { locale: language })
        },
        {
          label: T('confirm new password', { locale: language }),
          placeholder: T('minimum character password', { locale: language }),
          type: 'password',
          name: 'confirmNewPassword',
          required: true,
          match: true,
          matchError: T('password not match', { locale: language })
        }
      ]
    }
  }

  onComplete = (fields: Array<IField>) => {
    const finalFields: { [key: string]: any } = {}
    fields.forEach((x) => {
      finalFields[x.name] = x.value
    })
    this.changePassword(finalFields as IChangePassword)
  }

  changePassword = (data: IChangePassword) => {
    const { env, onSuccess, onFailure } = this.props
    this.setState({ isLoading: true })
    API.Auth.ChangePassword(
      env,
      data,
      (res) => {
        onSuccess && onSuccess(res)
      },
      (err) => {
        onFailure && onFailure(err)
      },
      () => {
        this.setState({ isLoading: false })
      }
    )
  }

  render() {
    const { isLoading, fields } = this.state
    const { language, env } = this.props

    const BUTTON = ({
      isLoading: isLoading,
      label: T('change password', { locale: language }),
      disabled: isLoading,
      variant: 'primary',
      block: true
    } as unknown) as IButtonProps

    return (
      <Row fullWidth>
        <Col fullWidth>
          <Row
            fullWidth
            justify='center'
            className={`${styles.imageContainer}`}
          >
            <img
              src={ASSET.resetPassword}
              style={{ width: 100, height: 100 }}
            />
          </Row>
          <Row fullWidth>
            {fields ? (
              <DefaultForm
                env={env}
                fields={fields}
                button={BUTTON}
                language={language}
                onComplete={this.onComplete}
              />
            ) : undefined}
          </Row>
        </Col>
      </Row>
    )
  }
}

export default ChangePasswordForm
