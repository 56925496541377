import DATA from '../datas'
import ENVIRONMENT from '../constants/environment'
import STORAGE from '../constants/storage'
import { IUser } from '@evos/general-particle/dist/interfaces/account'
import {
  IResponse,
  IResult,
  IError
} from '@evos/general-particle/dist/interfaces/api'
import GeneralParticle from '@evos/general-particle'
import ENVS from '../utils/environment'
import Base from './base'

const { Set, Get } = GeneralParticle.Utils.Storage

const GetToken = (env: ENVIRONMENT, req?: any): string | undefined => {
  const token = Get(STORAGE.token + ENVS[env].STORAGE_SUFFIX, req)
  return token
}

const SetToken = (value: string, env: ENVIRONMENT, res?: any) => {
  Set(
    STORAGE.token + ENVS[env].STORAGE_SUFFIX,
    value,
    ENVS[env].STORAGE_DOMAIN,
    res
  )
}

const GetCurrent = async (
  env: ENVIRONMENT,
  req?: any
): Promise<IUser | undefined> => {
  const token = GetToken(env, req)
  let result
  if (token) {
    await Base(
      env,
      DATA.User.GetCurrent(env, req),
      (response: IResponse<IUser>) => {
        result = response.data?.data
      }
    )
  }
  return result
}

const Update = (
  env: ENVIRONMENT,
  data: IUser,
  onSuccess?: (data: IUser) => any,
  onFailed?: (error?: string) => any,
  onCallback?: (res: IUser) => any
) => {
  const token = GetToken(env)
  let result
  if (token) {
    Base(
      env,
      DATA.User.Update(env, data),
      (response: IResponse<IUser>) => {
        if (response.data?.success && response.data?.data) {
          onSuccess && onSuccess(response.data.data)
        } else {
          onFailed &&
            onFailed(response.data?.errors?.map((x) => x.message).join(','))
        }
      },
      (err) => {
        onFailed && onFailed(err)
      },
      (final) => {
        onCallback && onCallback(final)
      }
    )
  }
  return result
}

const VerifyDuplicatePhoneNumber = async (
  env: ENVIRONMENT,
  phoneNumber: string
) =>
  DATA.User.VerifyDuplicatePhoneNumber(env, phoneNumber)
    .then((response: IResponse<null>) => {
      const finalResponse: IResult<null> | undefined = response.data
      if (finalResponse?.errors) {
        // error from our BE
        return finalResponse.errors.map((x: IError) => x.message).join(', ')
      }
      return null
    })
    // error from cotter
    .catch((e) =>
      e.response.data.errors.map((x: IError) => x.message).join(', ')
    )

const UpdateEmail = (
  env: ENVIRONMENT,
  email: string,
  onSuccess?: (data: any) => any,
  onFailed?: (error?: string) => any,
  onCallback?: (res: any) => any
) => {
  const token = GetToken(env)
  let result
  if (token) {
    Base(
      env,
      DATA.User.UpdateEmail(env, email),
      (response: IResponse<any>) => {
        if (response.data?.success) {
          onSuccess && onSuccess(response.data.success)
        } else {
          onFailed &&
            onFailed(response.data?.errors?.map((x) => x.message).join(','))
        }
      },
      (err) => {
        onFailed && onFailed(err)
      },
      (final) => {
        onCallback && onCallback(final)
      }
    )
  }
  return result
}

export default {
  GetToken,
  SetToken,
  GetCurrent,
  Update,
  VerifyDuplicatePhoneNumber,
  UpdateEmail
}
