import * as React from 'react'
import ASSET from '../../constants/asset'
import styles from './index.style.css'
import T from '../../utils/translation'
import Text from '../../shared-components/text'
import Row from '../../shared-components/grid/row'
import Column from '../../shared-components/grid/column'
import { IBaseProps } from '../../interfaces/base'

export type INPUT_TYPE = 'text' | 'number' | 'textarea' | 'email' | 'password'

interface IProps
  extends IBaseProps,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  /*
   * input type
   * default is text
   */
  type: INPUT_TYPE
  /*
   * input name
   */
  name: string
  /*
   * function triggered when there is value changes
   */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  /*
   * input value
   */
  value?: string
  /*
   * define row of input
   * applicable on textarea type only
   */
  rows?: number
  /*
   * input placeholder
   */
  placeholder?: string
  /*
   * wether input is required or not
   */
  required?: boolean
  /*
   * input`s error message
   */
  error?: string
  /*
   * max length of input
   */
  maxLength?: number
  /*
   * min length of input
   */
  minLength?: number
  /**
   * editable of input
   */
  readOnly?: boolean
}

interface IStates {
  typePassword?: string
  error?: string
  value?: string
}

class Input extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      typePassword: this.props.type
    }
  }

  componentWillReceiveProps(nextProps: IProps) {
    this.setState({ error: nextProps.error, value: nextProps.value })
  }

  onChange = (event: any) => {
    const { name, maxLength, onChange } = this.props
    const { value } = event.target
    const ev = { ...event, target: { name, value: value.slice(0, maxLength) } }
    onChange(maxLength ? ev : event)
  }

  onBlur = () => {
    if (this.props.required) {
      this.setState({
        error: !this.state.value
          ? T('fill field', { locale: this.props.language })
          : ''
      })
    }
  }

  onHide = () => {
    this.setState((state) => ({
      typePassword: state.typePassword === 'password' ? 'text' : 'password'
    }))
  }

  InputComponent = () => {
    const {
      name,
      rows,
      type,
      placeholder,
      required,
      maxLength,
      minLength,
      readOnly
    }: IProps = this.props
    const { typePassword, value } = this.state
    switch (type) {
      case 'textarea':
        return (
          <textarea
            required={required}
            onBlur={this.onBlur}
            className={styles.input}
            placeholder={placeholder}
            value={value || ''}
            rows={rows}
            name={name}
            onChange={this.onChange}
            maxLength={maxLength}
            minLength={minLength}
            readOnly={readOnly}
          />
        )
        break
      case 'password':
        return (
          <Row fullWidth>
            <Column fullFlex>
              <input
                type={typePassword}
                onBlur={this.onBlur}
                required={required}
                className={styles.input}
                placeholder={placeholder}
                value={value || ''}
                name={name}
                onChange={this.onChange}
                style={{ flex: 1 }}
                maxLength={maxLength}
                minLength={minLength}
                readOnly={readOnly}
              />
            </Column>
            <button className={styles.btn_see} onClick={this.onHide}>
              <img
                src={typePassword === 'password' ? ASSET.show : ASSET.hide}
                className={styles.icon_visible}
              />
            </button>
          </Row>
        )
        break
      default:
        return (
          <input
            type={type}
            onBlur={this.onBlur}
            required={required}
            className={styles.input}
            placeholder={placeholder}
            value={value?.slice(0, maxLength) || ''}
            name={name}
            onChange={this.onChange}
            maxLength={maxLength}
            minLength={minLength}
            readOnly={readOnly}
          />
        )
        break
    }
  }

  render() {
    const { style, readOnly }: IProps = this.props
    const { error } = this.state
    return (
      <Row fullWidth>
        <Column fullWidth>
          <Row
            className={`${styles.input_container} ${
              readOnly ? styles.read_only : ''
            }`}
            style={style}
          >
            {this.InputComponent()}
          </Row>
          {error && (
            <Row>
              <Text color='red' size={12}>
                {error}
              </Text>
            </Row>
          )}
        </Column>
      </Row>
    )
  }
}

export default Input
