import { IError, IResponse } from '@evos/general-particle/dist/interfaces/api'
import ENVIRONMENT from '../constants/environment'
import AuthAPI from './auth'

export default function (
  env: ENVIRONMENT,
  data: Promise<any>,
  onSuccess: (data: IResponse<any>) => any,
  onFailed?: (error: string) => any,
  callback?: (res: any) => any
) {
  return data
    .then((response: IResponse<any>) => {
      onSuccess(response)
      return response
    })
    .catch((error: any) => {
      if (
        error.response?.status &&
        [401, 403].includes(error.response.status)
      ) {
        AuthAPI.Logout(env)
        window.location.href = '/'
      }
      if (
        onFailed &&
        error.response &&
        error.response.data &&
        error.response.data.errors
      )
        onFailed(
          error.response.data.errors.map((x: IError) => x.message).join(',')
        )
    })
    .then((final) => {
      if (callback) callback(final)
    })
}
