import UserAPI from '../apis/user'
import ENVIRONMENT from '../constants/environment'
import ENVS from '../utils/environment'
import GeneralParticle from '@evos/general-particle'

const { Utils } = GeneralParticle
const { GetToken } = UserAPI

export default function (env: ENVIRONMENT, req?: any) {
  const customHeader = new Headers()
  const token = GetToken(env, req)
  if (token) {
    customHeader.append('authorization', `Bearer ${token}`)
  }
  return Utils.API({
    baseURL: ENVS[env].API_URL,
    customHeader
  })
}
