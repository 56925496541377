import DATA from '../datas'
import ENVIRONMENT from '../constants/environment'
import { ICity } from '@evos/general-particle/dist/interfaces/account'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'

const GetList = async (
  env: ENVIRONMENT,
  provinceId: number
): Promise<Array<ICity> | undefined> => {
  let result
  await Base(
    env,
    DATA.City.GetList(env, provinceId),
    (response: IResponse<Array<ICity>>) => {
      result = response.data?.data
    }
  )
  return result
}

export default {
  GetList
}
