import '../../modules.style.css'

import * as React from 'react'
import Text from '../text'
import styles from './index.style.css'
import Spinner from '../spinner'

export interface IButtonProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  /*
   * function triggered on click
   */
  onClick?: (event?: any) => void
  /*
   * button variant
   */
  variant?: 'primary' | 'outline' | 'transparent'
  /*
   * css classname
   */
  className?: string
  /*
   * whether button disabled
   */
  disabled?: boolean
  /*
   * button name
   */
  name?: string
  /*
   * wether button is block (full length or not)
   */
  block?: boolean
  /*
   * button label
   * if not defined, use children
   */
  label?: string
  /*
   * loading state
   */
  isLoading?: boolean
  /**
   * label size
   */
  textSize?: number
  /**
   * label weigth
   */
  weight?: 'bold' | 'regular' | 'semibold'
}

class ButtonComponent extends React.Component<IButtonProps> {
  render() {
    const {
      onClick,
      variant,
      className,
      disabled,
      name,
      children,
      block,
      style,
      label,
      isLoading,
      textSize,
      weight
    } = this.props

    const btnLabel = () => {
      return (
        <div className={styles.textContainer}>
          {isLoading && <Spinner style={{ marginRight: 10 }} />}
          <Text
            size={textSize || 16}
            lineHeight={20}
            weight={weight || 'bold'}
            color={variant === 'primary' ? 'white' : 'primary-blue'}
          >
            {label}
          </Text>
        </div>
      )
    }

    return (
      <button
        disabled={disabled}
        name={name}
        onClick={onClick}
        className={`${styles[variant || 'primary']} ${
          block ? styles.block : ''
        } ${className}`}
        style={{ ...style, opacity: isLoading ? 0.5 : 1 }}
      >
        {label && btnLabel()}
        {!label && children}
      </button>
    )
  }
}

export default ButtonComponent
