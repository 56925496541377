import { ICountry } from '@evos/general-particle/dist/interfaces/account'

const DEFAULT_PREFERENCE: ICountry = {
  id: 'ID',
  name: 'Indonesia',
  image: 'https://cdn.britannica.com/48/1648-004-A33B72D8/Flag-Indonesia.jpg',
  currencyCode: 'IDR',
  currencyPrefix: 'Rp',
  currencySuffix: '',
  currencyThousandSeparator: '.',
  currencyDecimalSeparator: ',',
  currencyDecimalLength: 2,
  isActive: true
}

export default DEFAULT_PREFERENCE
