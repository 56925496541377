import ENVIRONMENT from '../constants/environment'
import Base from './base'
import { IResponse } from '@evos/general-particle/dist/interfaces/api'
import { IPointLog } from '@evos/general-particle/dist/interfaces/account'

export default {
  GetList: (env: ENVIRONMENT, req?: any): Promise<IResponse<IPointLog>> => {
    return Base(env, req).get(`/accounts/v1/private/users/point`)
  }
}
