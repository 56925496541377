const COLORS = {
  primaryBlue: '#0B3886',
  primaryBlack: '#000000',
  secondaryBlack: '#757575',
  tertiaryBlack: '#D7D7D7',
  red: '#D30007',
  yellow: '#F8D15C',
  white: '#FFFFFF',
  transparentWhite: '#FFFFFFB3',
  statusGreen: '#148712',
  green: '#1AA016',
  greyOne: '#F7F7F7',
  orange: '#FC8338',
  background: '#EBEBEB',
  layoutBackground: 'rgb(246,246,246)',
  inputBackground: '#EFF2F5'
}

export default COLORS
